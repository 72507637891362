.searchBar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-top: 12px;
    margin-bottom: 12px;
}
.inputText{
    height: 3vh;
    border-radius: 8px;
}
.btn{
    height: 4vh;
    border-radius: 8px;
}
.btn:hover{
    background-color: rgba(0, 0, 0, 0.507);
    cursor: pointer;
    color: rgb(255, 255, 255);
}