.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 390px;
    height: 70px;
    margin-bottom: 5px;
    margin-left: 5px;
    border-radius: 10px;
}

.container:hover {
    cursor: pointer;
    background-color: rgb(51, 50, 50);
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
}

.point {
    width: 10px;
    height: 10px;
}

.icon {
    width: 37px;
    height: 37px;
    margin-right: 10px;
    margin-left: 5px;
    margin-bottom: 30px;
    border-radius: 50%;
    margin-top: 5px;
    border: 2px solid #E4FD3F;
}

.entrada{
    width: 350px;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    justify-content: left;
    margin-top: 5px;
}

.nombre {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #E4FD3F;
    margin-right: 5px;
}

.nombreLeido{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #c7da4b;
    margin-right: 5px; 
}

.mensaje {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.mensajeLeido{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #e6e4e4;
}

.fecha {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #A3A3A3;
}

.textBtn {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #E4FD3F;
    margin-top: 10px;
}

.textBtn:hover {
    cursor: pointer;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .container{
        width: 90vw;
        height: 18vw;
        margin-bottom: 3vw;
        margin-left: 1vw;
    }
    .icon{
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
        margin-left: 1vw;
        margin-bottom: 5vw;
        margin-top: 1vw;
    }
    .info{
        width: 75vw;
    }
    .entrada{
        width: 75vw;
        margin-right: 0px;
    }
    .nombreLeido{
        font-size: 3vw;
        margin-right: 1vw;
    }
    .mensajeLeido{
        font-size: 3vw;
        margin-right: 1vw;
    }
    .fecha{
        font-size: 3vw;
    }
    .textBtn{
        font-size: 3vw;
        margin-top: 2vw;
    }
}