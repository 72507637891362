.politica {
    width: 90%;
    height: 90%;
    overflow: auto;
    margin-top: 15vh;
    background-color: rgb(236, 236, 237);
    border-radius: 10px;
}

.title {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 600;
    line-height: 35.84px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}

.subTitle {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.44px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
}

.into {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.61px;
    text-align: left;
    color: #000000;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.point{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.61px;
    text-align: left;
    color: #000000;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.subPonit{
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.61px;
    text-align: left;
    color: #000000;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 20px;
}

.btn{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    height: auto;
    font-weight: 800;
    line-height: 12px;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
    cursor: pointer;
}