.inicio {
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.contHero {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.hero {
    width: 100vw;
    height: 500px;
    display: flex;
    flex-direction: row;
}

.contM {
    width: 480px;
    display: flex;
    flex-direction: column;
    margin-left: 160px;
    margin-right: 50px;
    z-index: 1;
}

.Mprincipal {
    font-family: Oswald;
    font-size: 54px;
    font-weight: 600;
    line-height: 75.6px;
    text-align: left;
    color: #FFFFFF;
}

.conSegundo {
    display: flex;
    width: 433px;
    margin-top: 20px;
}

.MSegundo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    color: #FFFFFF;
}

.contBtn {
    display: flex;
    flex-direction: row;
    width: 345px;
    height: 46px;
    justify-content: space-between;
    margin-top: 20px;
}

.btnR {
    width: 158px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
}

.btnR:hover{
    cursor: pointer;
    background-color: #e4fd3f86;
}

.btnI {
    width: 158px;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
}

.btnI:hover{
    cursor: pointer;
    background-color: #ffffffc2;
}

.contImg {
    width: 800px;
    height: 500px;
}

.imgOne {
    width: 800px;
    height: 500px;
    opacity: 80%;
    mix-blend-mode: lighten;
}

.porque {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 80px;
    padding-left: 160px;
    padding-bottom: 150px;
    background-image: url("../../../assets/gradienteC.png");
    background-repeat: no-repeat;
    background-size: 900px 900px;
}

.title {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 600;
    line-height: 62.24px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 400px;
    margin-bottom: 80px;
}

.contContenido {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.contenido {
    width: 434px;
    height: 372px;
    display: flex;
    flex-direction: column;
    margin-right: 160px;
    margin-top: 60px;
}

.sub {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 600;
    line-height: 41.5px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.txt {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.txtB {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 12px;
}

.contList {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
}

.listB {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 12px;
}

.balon {
    width: 24px;
    height: 24px;
}

.contImages {
    position: relative;
    display: flex;
    width: 650px;
    height: 500px;
}

.contImagesE {
    position: relative;
    display: flex;
    width: 650px;
    height: 500px;
    margin-right: 40px;
}

.contImagesC {
    position: relative;
    display: flex;
    width: 650px;
    height: 500px;
    margin-left: 100px;
}

.imgTwo {
    width: 530px;
    height: 500px;
    border-radius: 12px;
}

.imgFour {
    width: 437px;
    height: 339px;
    border-radius: 12px;
}

.imgAppOne {
    width: 220px;
    height: 360px;
    position: absolute;
    z-index: 1;
    bottom: 230px;
    right: 480px;
}

.imgAppTwo {
    width: 290px;
    height: 190px;
    position: absolute;
    z-index: 1;
    top: 380px;
    left: 340px;
}

.imgAppTree {
    width: 265px;
    height: 380px;
    position: absolute;
    z-index: 1;
    bottom: 220px;
    left: 320px;
}

.imgAppFour {
    width: 608px;
    height: 122px;
    position: absolute;
    z-index: 1;
    top: 420px;
    right: 200px;
}

.imgAppFive {
    width: 517px;
    height: 403px;
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 100px;
}

.entrenadores {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding-left: 160px;
    padding-bottom: 160px;
    background-image: url("../../../assets/gradienteR.png");
    background-repeat: no-repeat;
    background-size: 900px 900px;
    background-position: right 1px;
}

.contImgE {
    display: flex;
    width: 600px;
    height: 550px;
}

.contEntre {
    display: flex;
    flex-direction: column;
    width: 434px;
    height: 428px;
    margin-top: 40px;
}

.comunidad {
    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    background-image: url("../../../assets/fondoComunidad.png");
    padding-top: 80px;
    padding-left: 160px;
    padding-bottom: 5px;
    padding-right: 130px;
}

.contDato {
    display: flex;
    flex-direction: column;
}

.subT {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 600;
    line-height: 62.24px;
    text-align: center;
    color: #000000;
}

.contNum {
    display: flex;
    flex-direction: row;
    width: 620px;
    justify-content: space-between;
}

.jugadores {
    display: flex;
    flex-direction: column;
    width: 280px;
}

.num {
    font-family: Oswald;
    font-size: 96px;
    font-weight: 600;
    line-height: 142.27px;
    text-align: left;
    color: #000000;
}

.jugadoresTxt {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    color: #000000;
}

.botones {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
}

.btnRegis {
    width: 158px;
    height: 46px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
    margin-right: 30px;
}

.btnRegis:hover{
    cursor: pointer;
    background-color: #000000;
    color: #FFFFFF;
}

.btnIniciar {
    width: 158px;
    height: 46px;
    background-color: #000000;;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
}

.btnIniciar:hover{
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
}

.pie {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #000000;
    padding-top: 100px;
    padding-left: 160px;
    padding-right: 150px;
    padding-bottom: 100px;
}

.contMenu {
    display: flex;
    flex-direction: row;
    width: 1100px;
    justify-content: space-between;
    margin-bottom: 60px;
}

.logo {
    width: 100px;
    height: 40px;
}

.logo:hover{
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: row;
    width: 355px;
    justify-content: space-between;
}

.btnM {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
}

.btnM:hover{
    cursor: pointer;
    color: #E4FD3F;
    border-bottom: 3px solid #E4FD3F;
}

.redes {
    display: flex;
    flex-direction: row;
    width: 130px;
    justify-content: space-between;
}

.iconR {
    width: 30px;
    height: 30px;
}

.iconR:hover{
    cursor: pointer;
    filter: invert(10%) sepia(100%) saturate(10000%);
}

.contContacto {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.dataContact {
    display: flex;
    flex-direction: column;
    width: 257px;
    height: 181px;
    margin-right: 130px;
}

.contContac {
    width: 127px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E4FD3F;
    border-radius: 7px;
    margin-bottom: 27px;
}

.contContac:hover{
    cursor: pointer;
    background-color: #e4fd3fb8;
}

.txtContac {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: center;
    color: #000000;
}

.contact {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.mailing {
    width: 720px;
    height: 181px;
    display: flex;
    flex-direction: row;
    background-color: #292A32;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
}

.inpMail {
    width: 285px;
    height: 65px;
    border-radius: 14px;
    background-color: transparent;
    padding-left: 22px;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 20px;
    border: 1px solid #FFFFFF;
}

.inpMail::placeholder{
    color: #FFFFFF;
}

.btnMail {
    width: 323px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E4FD3F;
    border-radius: 14px;
}

.btnMail:hover{
    cursor: pointer;
    background-color: #00000023;
    .txtMail{
        color: #FFFFFF;
    }
}

.txtMail {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
    .inicio{
        margin-top: 25vw;
    }
    .hero{
        height: auto;
        margin-bottom: 15vw;
        flex-direction: column;
    }
    .contBtn{
        margin-top: 15vw;
    }
    .contImg {
        /* display: none; */
        width: 100vw;
        display: flex;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-top: 15vw;
    }
    .imgOne {
        width: 90%;
        height: 90%;
        opacity: 80%;
        border-radius: 10px;
        mix-blend-mode: lighten;
    }
    .contM{
        width: 100vw;
        margin-left: 0px;
        margin-right: 0px;
    }
    .conSegundo {
        width: 100%;
        margin-top: 0px;
    }
    .contContenido{
        flex-direction: column;
    }
    .contenido{
        margin-top: 0px;
        width: 100vw;
        height: auto;
        margin-right: 0px;
    }
    .imgAppOne {
        width: 35vw;
        height: 60vw;
        bottom: 45vw;
        right: 63vw;
    }
    .imgAppTwo {
        width: 50vw;
        height: 30vw;
        top: 68vw;
        left: 46vw;
    }
    .contImages {
        /* display: none; */
        width: 100vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30vw;
    }
    .imgTwo{
        width: 90vw;
        height: 90vw;
        opacity: 80%;
        border-radius: 10px;
        mix-blend-mode: lighten;  
    }
    .imgAppTree {
        width: 45vw;
        height: 50vw;
        bottom: 52vw;
        left: 52vw;
    }
    .imgAppFour {
        width: 100vw;
        height: 20vw;
        top: 80vw;
        right: 15vw;
    }
    .sub{
        text-align: center;
        margin-bottom: 15vw;
        font-size: 6vw;
        line-height: 10vw;
    }
    .txt{
        text-align: center;
        margin-left: 2vw;
        margin-right: 2vw;
        margin-bottom: 15vw;
        font-size: 5vw;
        line-height: 7vw;
    }
    .txtB{
        font-size: 4vw;
        line-height: 7vw; 
    }
    .contList{
        width: 100vw;
    }
    .list{
        height: auto;
        margin-top: 2vw;
        margin-bottom: 2vw;
        margin-left: 3vw;
        margin-right: 2vw;
    }
    .porque {
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .entrenadores {
        padding-left: 0px;
        flex-direction: column-reverse;
        padding-bottom: 25vw;
    }
    .contImagesE {
        /* display: none; */
        width: 100vw;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30vw;
        margin-right: 0px;
    }
    .contEntre {
        width: 100vw;
        height: auto;
        margin-top: 30vw;
    }
    .title {
        margin-bottom: 15vw;
        margin-top: 0px;
        text-align: center;
        margin-left: 2vw;
        margin-right: 2vw;
        font-size: 8vw;
        line-height: 12vw;
    }
    .Mprincipal {
        font-size: 9vw;
        font-weight: 700;
        line-height: 12vw;
        text-align: center;
        margin-bottom: 30px;
        margin-top: 0px;
        margin-left: 2vw;
        margin-right: 2vw;
        margin-bottom: 15vw;
    }
    .listB{
        height: auto;
        margin-top: 2vw;
        margin-bottom: 2vw;
        margin-left: 3vw;
        margin-right: 2vw;
    }
    .MSegundo {
        font-size: 5vw;
        font-weight: 400;
        line-height: 7vw;
        text-align: center;
        margin-left: 2vw;
        margin-right: 2vw;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .contBtn{
        width: 100vw;
        justify-content: center;
    }
    .btnR{
        width: 40vw;
        margin-right: 10vw;
        font-size: 4vw;
        height: 10vw;
    }
    .btnI{
        width: 40vw;
        font-size: 4vw;
        height: 10vw;
    }
}