.container {
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #262626;
    border-radius: 10px;
    margin: 8px;
}

.contUser {
    display: flex;
    flex-direction: row;
}

.user {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.foto {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 15px;
}

.name {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.posicion {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.contBSD {
    width: 75px;
    height: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E4FD3F;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.score {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #1C1D1E;
}

.BSDTxt {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: #1C1D1E;
}

.botones {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.btn {
    width: 80px;
    height: 40PX;
    border-radius: 8px;
    background-color: #0F0F0F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 20px;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .container {
        height: 12vh;
        width: 94vw;
        margin: 2vw;
        margin-left: 4vw;
    }
    
    .user {
        width: 35vw;
    }

    .contUser{
        align-items: center;
    }
    
    .foto {
        width: 16vw;
        height: 16vw;
        margin: 2vw;
    }
    
    .name {
        font-size: 4vw;
        margin-bottom: 1vw;
    }
    
    .posicion {
        font-size: 3vw;
    }
    
    .contBSD {
        width: 14vw;
        height: 8vh;
    }
    
    .score {
        font-size: 4vw;
    }
    
    .BSDTxt {
        font-size: 3vw;
    }
    
    .botones {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2vw;
        
    }
    
    .btn {
        width: 18vw;
        height: 4.5vh;
        margin-top: 1vw;
        margin-bottom: 1vw;
        font-size: 2.5vw;
        margin-right: 0px;
        font-weight: 400;
        line-height: 13.61px;
    }
    
}