.modalImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background-color: #ffffff4e;
    z-index: 9;
}

.conteiner {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 93px;
    right: 300px;
    width: 900px;
    height: 530px;
    background-color: #0F0F0F;
    background-image: url("../../assets/fondoLimpio.png");
    background-size: 100% 100%;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    z-index: 1;
    color: white;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.btnUpload {
    width: 400px;
    height: 150px;
    cursor: pointer;
}

.contEdit {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.contEditImage {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.contCanvas {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 90%;
}

.contPreview {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
}

.previewImg {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 2px solid yellow;
}

.btnSave2 {
    width: 280px;
    height: 46px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 120px;
    cursor: pointer;
}

.btnCrop {
    width: 210px;
    height: 46px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    color: #1C1D1E;
    margin-top: 12px;
}

.btnCrop:hover {
    background-color: #333333;
}

.nombre {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 400;
    line-height: 50.4px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 40px;
    margin-top: 20px;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espaciado entre botones */
    margin-top: 12px; /* Espaciado superior */
}

/* Botón más pequeño */
.smallButton {
    width: 210px;
    height: 46px;
    border-radius: 8px;
    cursor: pointer;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    color: #FFFFFF;
    margin-top: 12px;
}
.backButton {
    position:relative;
    bottom: 30%;
    right: 27%;
    width: 40px;
    height: 40px;
    z-index: 2;
    cursor: pointer;
}

@media screen and (max-width: 767px){
   
    
    .conteiner {
        flex-direction: column;
        position: fixed;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/fondoLimpio.png");
        background-size: 100% 100%;
        padding: 0px;
        border-radius: 0px;
        justify-content: center;
        align-items: center;
    }
    
    .btnUpload {
        width: 100%;
        height: 150px;
        cursor: pointer;
    }
    
    .contEdit {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    
    .contEditImage {
        display: flex;
        flex-direction: column;
        width: auto;
        justify-content: flex-start;
        align-items: center;
    }
    
    .contCanvas {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 90%;
    }
    
    .contPreview {
        display: flex;
        flex-direction: column;
        width: auto;
        justify-content: flex-start;
        align-items: center;
    }
    
    .previewImg {
        width: 150PX;
        height: 150px;
        border-radius: 100%;

       
    }

    
    .btnSave2 {
        width: 155px;
        height: 46px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #E4FD3F;
        font-family: Raleway;
        font-size: 15px;
        font-weight: 600;
        line-height: 17.61px;
        color: #0F0F0F ;
        margin-top: 12px;
        }
    
    .btnCrop {
        width: 155px;
        height: 46px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #E4FD3F;
        font-family: Raleway;
        font-size: 15px;
        font-weight: 600;
        line-height: 17.61px;
        color: #1C1D1E;
        margin-top: 12px;
    }
    
    .btnCrop:hover {
        width: 210px;
        height: 46px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #E4FD3F;
        font-family: Raleway;
        font-size: 15px;
        font-weight: 600;
        line-height: 17.61px;
        color: #0F0F0F ;
        margin-top: 12px;
    }
    
    .nombre {
        font-family: Oswald;
        font-size: 30px;
        font-weight: 400;
        line-height: 50.4px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 40px;
        margin-top: 20px;
    }
    
    .buttonGroup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px; /* Espaciado entre botones */
        margin-top: 12px; /* Espaciado superior */
    }
    
    /* Botón más pequeño */
    .smallButton {
        width: 210px;
        height: 46px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #262626;
        font-family: Raleway;
        font-size: 15px;
        font-weight: 600;
        line-height: 17.61px;
        color: #FFFFFF;
        margin-top: 12px;
    }
    .backButton {
        position: fixed;
        top: 10vh;
        left: 3vw;
        width: 40px;
        height: 40px;
        }
}
