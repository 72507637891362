.conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 1800px;
    height: 1200px;
    top: 0px;
    background-color: #ffffff4e;
    z-index: 9;
}

.modalContent {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 100px;
    right: 350px;
    width: 800px;
    height: 500px;
    background-color: #0F0F0F;
    background-image: url("../../assets/fondoLimpio.png");
    background-size: 100% 100%;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    z-index: 1000;
    color: white;
    border-radius: 10px;
}
.contTitle {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 20px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px
}

.conSubGrafico {
    width: 300px;
    height: 400px;
    padding: 15px;
    background-color: #1a1a1a;
    border-radius: 8px;
}

.contHistorico {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 480px;
    justify-content: left;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: 20px;
}

.txtCerrar{
    font-family: Raleway;
    font-size: 28px;
    font-weight: 700;
    line-height: 22.31px;
    text-align: right;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
    margin-left: auto;
}

.contSubHistorico {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.txtFecha{
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.txtAnalisis{
    font-family: Oswald;
    font-size: 24px;
    font-weight: 500;
    line-height: 31.3px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px;
    margin-right: 20px;
}

.contAnalisis{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.contResumen{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.txtResumen{
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.61px;
    text-align: left;
    color: white;
}

.contEntrenamiento{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.contMetricas{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.contComentario{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.txtComentario{
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    background-color: #262626;
    color: #ffffff;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.btnGuardar{
    width: 96%;
    height: 40px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    border-radius: 8px;
    cursor: pointer;
}

/*
Lo de atras ya se factoreo
*/

.contData {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.sub {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 4px;
}

.data {
    font-family: Oswald;
    font-size: 32.29px;
    font-weight: 400;
    line-height: 26.29px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 3px;
}

.unidad {
    font-family: Raleway;
    font-size: 26.66px;
    font-weight: 400;
    line-height: 31.3px;
    text-align: center;
    color: #FFFFFF;
}

.contFecha {
    display: flex;
    flex-direction: column;
}

.contSubfecha {
    display: flex;
    flex-direction: column;
}

.subFecha {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.fecha {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: right;
    color: #FFFFFF;
}

.contEdit {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-left: 30px;
}

.titEdit {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: white;
    margin-bottom: 20px;
}

.avisoG {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: white;
}

.contAviso {
    display: flex;
    width: 350px;
    margin-top: 40px;
}

.avisoH {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: white;
}

.subir {
    width: 300px;
    height: 100px;
    margin-top: 25px;
    cursor: pointer;
}

.contVideo {
    display: flex;
    width: 350px;
    height: 250px;
    background-color: white;
    border-radius: 10px;
}

.checkboxP {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #262626;
    background-color: #262626;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}

.checkboxP:checked {
    background-color: #E4FD3F;
    /* border-color: #f5f5f5; */
    border: 4px solid rgb(62, 62, 62);
}

.contOption{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    align-items: center;
}

.inputext {
    /* margin-bottom: 8px; */
    display: none;
}

.slash{
    margin-right: 10px;
}

.txtSi {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: white;
    margin-top: 5px;
}

.txtNo {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: white;
    margin-top: 5px;
    margin-right: 100px;
}



.txtEnviar{
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: #E4FD3F;
    cursor: pointer;
}

.txtCerrar:hover{
    color: #FFFFFF;
    cursor: pointer;
}

.contBtnG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
}

.txtUnidad {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: white;
    margin-right: 50px;
    margin-top: 10px;
}

.btnG {
    width: 134px;
    height: 40px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 18px;
    border-radius: 8px;
}

.btnGLoad {
    width: 134px;
    height: 40px;
    background-color: #4f504f;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #ffffff;
    margin-top: 18px;
    border-radius: 8px;
}

.btnG:hover {
    cursor: pointer;
    background-color: #e4fd3fcc;
}

.titleM {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px
}

.titleMAnd {
    display: none;
}

.titleM:hover {
    color: #E4FD3F;
    cursor: pointer;
}

.contMenu {
    display: flex;
    flex-direction: row;
    width: 362px;
    height: 35px;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(38, 38, 38, 1);
    border-radius: 8px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    margin-top: 20px;
}

.btn {
    width: 191px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.btnTxt {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.btn:hover {
    cursor: pointer;
    background-color: #FFFFFF;

    .btnTxt {
        color: rgba(28, 29, 30, 1);
    }
}

.contComparativo {
    display: flex;
    flex-direction: column;
    width: 396px;
    height: 450px;
    margin-top: 10px;
    overflow-y: auto;
    scrollbar-width: none;
}

.posicion {
    width: 380px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
}

.iconM {
    width: 30px;
    height: 45px;
    margin-left: 5px;
    margin-top: 8px;
}

.contInfoUser {
    display: flex;
    flex-direction: row;
    width: 239px;
    justify-content: left;
    align-items: center;
    margin-left: 0px;
}

.userFoto {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 2px solid rgba(228, 253, 63, 1);
    border-radius: 50%;
}

.infoUser {
    display: flex;
    flex-direction: column;
}

.nameUser {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 2px;
}

.info {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.contDataP {
    display: flex;
    flex-direction: row;
    width: 50px;
    align-items: flex-end;
    margin-right: 5px;
}

.dataL {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.unidad {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.posicionP {
    width: 380px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(38, 38, 38, 1);
    padding-top: 5px;
    padding-bottom: 5px;
}

.btnSelected {
    width: 191px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 1);
}

.btnTxtSelected {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: rgba(28, 29, 30, 1);
}

.inpG {
    width: 190px;
    height: 40px;
    margin-right: 12px;
    margin-top: 12px;
    border-radius: 8px;
    background-color: #262626;
    color: #FFFFFF;
    border: 1px solid #A4A4A4;
}

.contEditBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contHistorial {
    width: 500px;
    height: 370px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    width: 396px;
    margin-top: 10px;
}

.numList {
    width: 30px;
    height: 45px;
    margin-top: 8px;
    margin-right: 20px;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    align-content: center;
    color: #FFFFFF;
}

@media screen and (max-width: 767px) {
    .conteiner {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 1001;
        position: fixed;
    }
    
    .modalContent {
        top: 17vw;
        right: 0px;
        width: 100vw;
        height: 83vh;
        padding: 0px;
        border-radius: 0px;
        overflow-y: scroll;
        scrollbar-width: none;
        flex-direction: column;
    }

    .modalContentAnd {
        display: flex;
        flex-direction: column;
        top: 17vw;
        right: 0px;
        width: 100vw;
        height: 83vh;
        padding: 0px;
        border-radius: 0px;
        overflow-y: scroll;
        scrollbar-width: none;
        align-items: center;
    }
    
    .contGrafica {
        width: 98vw;
    }

    .contGraficaDes{
        display: none;
    }
    
    .contTitle {
        margin-top: 3vw;
        margin-left: 0px;
        width: 94vw;
    }
    
    .iconFuerza {
        width: 12vw;
        height: 12vw;
        margin-right: 5vw;
    }
    
    .title {
        font-size: 8vw;
        line-height:8vw;
        margin-top: 1vw;
    }
    
    .contInfo {
        margin-left: 0px;
        margin-top: 3vw;
        width: 94vw;
    }
    
    .sub {
        font-size: 4vw;
        margin-bottom: 1vw;
    }
    
    .data {
        font-size: 7vw;
        margin-right: 0px;
        line-height: 28px;
    }

    .dataL{
        font-size: 7vw;
    }
    
    .unidad {
        font-size: 3vw;
    }
    
    .subFecha {
        font-size: 4vw;
    }
    
    .fecha {
        font-size: 3vw;
    }
    
    .contEdit {
        display: flex;
        flex-direction: row;
        width: 96vw;
        margin-top: 7vw;
        margin-left: 0px;
        justify-content: center;
    }
    
    .titEdit {
        font-size: 5vw;
        margin-bottom: 1vw;
        margin-top: 5vw;
    }
    
    .avisoG {
        font-size: 3.5vw;
    }
    
    .contAviso {
        display: flex;
        width: 90vw;
        margin-top: 3vw;
    }
    
    .avisoH {
        font-size: 3.5vw;
    }
    
    .subir {
        width: 50vw;
        height: 10vh;
        margin-top: 3vw;
        cursor: pointer;
    }
    
    .contVideo {
        display: flex;
        width: 350px;
        height: 250px;
        background-color: white;
        border-radius: 10px;
    }
    
    .checkboxP {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 4px solid #262626;
        background-color: #262626;
        outline: none;
        cursor: pointer;
        margin-right: 10px;
    }
    
    .checkboxP:checked {
        background-color: #E4FD3F;
        /* border-color: #f5f5f5; */
        border: 4px solid rgb(62, 62, 62);
    }
    
    .contOption{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        align-items: center;
    }
    
    .inputext {
        /* margin-bottom: 8px; */
        display: none;
    }
    
    .slash{
        margin-right: 10px;
    }
    
    .txtSi {
        font-family: Raleway;
        font-size: 19px;
        font-weight: 500;
        line-height: 22.31px;
        text-align: center;
        color: white;
        margin-top: 5px;
    }
    
    .txtNo {
        font-family: Raleway;
        font-size: 19px;
        font-weight: 500;
        line-height: 22.31px;
        text-align: center;
        color: white;
        margin-top: 5px;
        margin-right: 100px;
    }
    
    .txtCerrar{
        font-family: Raleway;
        font-size: 19px;
        font-weight: 500;
        line-height: 22.31px;
        text-align: center;
        color: #E4FD3F;
        margin-left: 10px;
        cursor: pointer;
    }
    
    .txtEnviar{
        font-family: Raleway;
        font-size: 19px;
        font-weight: 500;
        line-height: 22.31px;
        text-align: center;
        color: #E4FD3F;
        cursor: pointer;
    }
    
    .txtCerrar:hover{
        color: #FFFFFF;
        cursor: pointer;
    }
    
    .contBtnG {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 0px;
        width: 92vw;
    }
    
    .txtUnidad {
        font-size: 4vw;
        margin-right: 11vw;
        margin-top: 2vw;
    }
    
    .btnG {
        width: 30vw;
        height: 10vw;
        font-size: 4vw;
        margin-top: 4vw;
        border-radius: 2vw;
    }
    
    .btnGLoad {
        width: 134px;
        height: 40px;
        background-color: #4f504f;
        font-family: Raleway;
        font-size: 15px;
        font-weight: 600;
        line-height: 17.61px;
        text-align: center;
        color: #ffffff;
        margin-top: 18px;
        border-radius: 8px;
    }
    
    .btnG:hover {
        cursor: pointer;
        background-color: #e4fd3fcc;
    }
    
    .conSubGrafico {
        margin-left: 3vw;
        margin-top: 3vw;
    }
    
    .contHistorico {
        width: 98vw;
        height: 55vh;
        justify-content: right;
        align-items: flex-end;
        margin-right: 0px;
        overflow-y: clip;
        scrollbar-width: none;
    }
    
    .titleM {
        display: none;
    }
    
    .titleMAnd {
        display: flex;
        position: fixed;
        top: 18vw;
        right: 5vw;
        z-index: 1003;
        font-size: 5vw;
        font-weight: 600;
        text-align: center;
        color: #E4FD3F;
    }
    
    .contSubHistorico {
        display: flex;
        flex-direction: column;
        width: 98vw;
        align-items: center;
    }
    
    .contMenu {
        width: 90vw;
        height: 5vh;
        padding: 2vw;
        margin-top: 3vw;
    }
    
    .btn {
        width: 42vw;
        height: 9vw;
    }
    
    .btnTxt {
        font-size: 4vw;
    }
    
    .contComparativo {
        width: 96vw;
        height: 45vh;
        margin-top: 2vw;
    }

    .contComparativoDes{
        display: none;
    }
    
    .posicion {
        width: 94vw;
        height: 15vw;
        padding-top: 1vh;
        padding-bottom: 1vh;
    }
    
    .iconM {
        width: 11vw;
        height: 14vw;
        margin-left: 0px;
        margin-top: 2vw;
    }
    
    .contInfoUser {
        width: 50vw;
        margin-left: 1vw;
    }
    
    .userFoto {
        width: 12vw;
        height: 12vw;
        margin-right: 3vw;
    }
    
    .infoUser {
        display: flex;
        flex-direction: column;
        width: 35vw;
    }
    
    .nameUser {
        font-size: 4vw;
        margin-bottom: 0px;
    }
    
    .info {
        font-size: 3.5vw;
    }
    
    .contDataP {
        width: 10vw;
        margin-right: 0px;
    }
    
    .posicionP {
        width: 94vw;
        height: 15vw;
        padding-top: 1vh;
        padding-bottom: 1vh;
    }
    
    .btnSelected {
        width: 43vw;
        height: 9vw;
    }
    
    .btnTxtSelected {
        font-size: 4vw;
    }
    
    .inpG {
        width: 43vw;
        height: 10vw;
        margin-right: 2vw;
        margin-top: 2vw;
    }
    
    .contHistorial {
        width: 500px;
        height: 370px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        scrollbar-width: none;
        width: 396px;
        margin-top: 10px;
    }

    .contHistorialDes{
        display: none;
    }
    
    .numList {
        width: 30px;
        height: 45px;
        margin-top: 8px;
        margin-right: 20px;
        font-family: Oswald;
        font-size: 14px;
        font-weight: 400;
        line-height: 12px;
        text-align: center;
        align-content: center;
        color: #FFFFFF;
    }
}