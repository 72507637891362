.container{
    /* background-color: rgb(23, 23, 23); */
    /* background-image: url("../../assets/Background.png"); */
    display: flex;
    flex-direction: row; 
    justify-content: center;
    width: 100vw;
}
.hero{
    width: 50vw;
    height: 40vh;
    margin-top: 12vh;
}
.contHero{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.fondo{
    width: 100%;
    max-width: 50vw;
    max-height: 40vh;
    border-radius: 20px;
}
.foto{
    margin-top: -70px;
    width: 150px;
    height: 150px;
    border-radius: 100px;
    padding-left: 12px;
}
.perfil{
    display: flex;
    flex-direction: row;
}
.nombre{
    color: rgb(255, 255, 255);
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 12px;
}
.perfil{
    color: rgb(25, 224, 3);
    margin-block-start: 0px;
    margin-block-end: 0px;
}
.icon{
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-left: 12px;
}
.icon:hover{
    cursor: pointer;
    filter:invert(100%);
}
