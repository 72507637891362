.container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #262626;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 4px;
    /* z-index: 2; */
    width: 100%;
}

.user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* width: 386px;
    height: 40px; */
    margin-top: 15px;
    margin-left: 15px;
}

.contUser {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 250px;
}

.contInfo {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.foto {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right: 10px;
    border: 2px solid #E4FD3F;
    cursor: pointer;
}

.secontContList{
    display: flex;
    flex-direction: column;
    background-color: rgb(29, 28, 28);
    width: 40%;
    height: 50%;
    border-radius: 12px;
    z-index: 3;
}

.contAplausosList{
    overflow:auto;
    scrollbar-width: none;
}

.btnAdd{
    width: 120px;
    height: 40px;
    border-radius: 10px;
    font-size: 18px;
    background-color: rgb(56, 56, 56);
    font-family: Raleway;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    margin-left: 100px;
    color: #FFFFFF;
    cursor: pointer;
}

.contAplauso{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    cursor: pointer;
    width: 90%;
}

.userDate{
    display: flex;
    flex-direction: row;
    margin-right: 0px;
    align-items: center;
    cursor: pointer;
}

.fotoAplauso{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: 1px solid #E4FD3F;
    margin-right: 10px; 
}

.aplausoTxt{
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 20.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 20px;
}

.userPosicion{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.nombre {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.nombre:hover {
    cursor: pointer;
}

.posicion {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.contTime {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: right;
    align-items: center;
    width: 150px;
    height: 40px;
}

.time {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #A4A4A4;
    margin-right: 10px;
}

.puntos {
    width: 30px;
    height: 30px;
}

.puntos:hover {
    cursor: pointer;
}

.contMsg {
    display: flex;
    /* width: 386px; */
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
}

.msg {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
}

.contImg {
    display: flex;
    /* width: 386px; */
    align-self: center;
    flex-direction: column;
    margin-bottom: 12px;
}

.img {
    width: 100%;
    border-radius: 12px;
    align-self: center;
}

.contBtnTxt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.mensajeEdit {
    width: 280px;
    height: 40px;
    background-color: #0F0F0F;
    color: white;
}

.actualizar {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
    color: #E4FD3F;
    margin-left: 10px;
    cursor: pointer;
}

.actualizar:hover {
    color: white;
}

.contVideo {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 386px;
}

.userOrigen {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 18px;
    margin-bottom: 10px;
}

.contMsgOrigen {
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 12px;
}

.contCompartido {
    margin-left: 10px;
}

/* .info {
    display: flex;
    flex-direction: row;
    width: 386px;
    height: 35px;
    justify-content: space-between;
} */

.contReaction {
    display: flex;
    flex-direction: row;
    /* width: 386px; */
    height: 35px;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 10px;
}

.contR {
    display: flex;
    flex-direction: row;
    width: 250px;
    height: 20px;
    margin-top: 10px;
}

.contM {
    display: flex;
    flex-direction: row;
    width: 100px;
    margin-bottom: 10px;
    justify-content: right;
}

.reactText {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: 5px;
}

.iconM {
    width: 15px;
    height: 25px;
    margin-top: 7px;
}

.medallas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    position: absolute;
    bottom: 35px;
    right: 20px;
    border-radius: 50px;
    width: 130px;
    height: 40px;
    /* margin-left: 60%; */
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: rgb(29, 28, 28);
}

.contAplausos{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-self: center;
    align-items: center;
    justify-content: center;
    position:fixed;
    bottom: 0px;
    left: 0px;
    border-radius: 5px;
    border: 1px solid white;
    width: 100%;
    height: 100%;
    z-index: 999999;
    margin-bottom: 0px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: rgba(255, 254, 254, 0.512);
}

.cerrar{
    width: 40px;
    height: 40px;
    background-color: #0F0F0F;
    color: aliceblue;
    font-size: 22px;
    border-radius: 100%;
    margin-bottom: 10px;
    margin-left: 43%;
    cursor: pointer;
}

.txtA{
    width: 25px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 50px;
}

.contMedalla {
    width: 35px;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.contMedalla:hover {
    cursor: pointer;
    background-color: rgba(68, 66, 66, 0.344);
}

.medalla {
    width: 35px;
    height: 35px;
}

.comentarios {
    margin-left: 12px;
    border-top: 1px solid black;
    width: 95%;
    height: 300px;
}

.Contcompartir {
    margin-left: 12px;
    margin-right: 12px;
    border-top: 1px solid black;
    width: 326px;
    height: 150px;
}

.msgComent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.contComent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 12px;
    width: 80%;
}

.inp {
    width: 300px;
    height: 60px;
    border-radius: 10px;
    background-color: #1C1D1E;
    margin-left: 24px;
    margin-right: 20px;
    margin-bottom: 10px;
    color: #FFFFFF;
}

.inp::placeholder {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #A3A3A3;
}

.btn {
    width: 100%;
    height: 40px;
    margin-left: 24px;
    margin-right: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 16px;
    background-color: #E4FD3F;
    color: #1C1D1E;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.contComentarios {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    height: 150px;
    border-radius: 10px;
}

.textarea {
    width: 97%;
    height: 60px;
    border-radius: 10px;
    background-color: #1C1D1E;
    margin-left: 24px;
    margin-right: 20px;

    color: #FFFFFF;
}

.charCount {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 10px;
    margin-right: 20px;
}

.fotoPubli {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    margin-right: 10px;
    margin-bottom: 70px;
    border: 2px solid #E4FD3F;
}












.compartir {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 12px;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.218);
}




.recomendarCont {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
}



/* .contInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
} */

.contInfo:hover {
    cursor: pointer;
}

.infoIcon {
    width: 15px;
    height: 15px;
}

.infoName {
    margin-left: 6px;
    color: rgb(255, 255, 255);
    font-size: 12px;
}

.recomendar {
    display: flex;
    flex-direction: row;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 10px;
}

.recomendar:hover {
    cursor: pointer;
}



.delete {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.delete:hover {
    cursor: pointer;
    filter: invert(100%);
}

.comentario {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
}

.commentUser {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 96%;
}

.comentCont {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    background-color: #0F0F0F;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 12px;
}

.userComnt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.userIcon {
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.userName {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px;
    margin-left: 15px;
}

.fecha {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 12px;
    color: white;
    margin-right: 12px;
}

.commentText {
    color: rgb(255, 255, 255);
    margin-left: 15px;
}



.userImg {
    width: 50px;
    height: 50px;
    border-radius: 100px;
}

.reactTextB {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 9px;
}

.reactTextC {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 11px;
    margin-left: 5px;
}

.reactTextTrue {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #E4FD3F;
    animation: bounce;
    animation-duration: 2s;
}

.iconT {
    width: 45px;
    height: 30px;
    margin-top: 10px;
}

.iconBack{
    display: none;
}

.contRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.iconMm{
    width: 40px;
    height: 40px; 
}

.iconM {
    width: 50px;
    height: 50px;
    margin-top: 10px;
}

.reactTextM {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 11px;
    margin-left: 5px;
}

@media screen and (max-width: 767px) {
    .container {
        margin-top: 5vw;
        margin-bottom: 1vw;
        width: 93vw;
        align-items: center;
    }
    
    .user {
        width: 88vw;
        height: 40px;
        margin-top: 3vw;
        margin-left: 0px;
        padding-left: 2vw;
    }

    .contUser {
        width: 60vw;
    }
    
    .contInfo {
        width: 40vw;
    }
    
    .foto {
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
    }
    
    .nombre {
        font-size: 3vw;
        margin-bottom: 1vw;
    }
    
    .posicion {
        font-size: 3vw;
    }
    
    .contTime {
        width: 38vw;
        height: 10vw;
    }
    
    .time {
        font-size: 3vw;
        margin-right: 2vw;
    }
    
    .puntos {
        width: 8vw;
        height: 8vw;
    }
    
    .contMsg {
        width: 83vw;
        margin: 3vw;
    }
    
    .msg {
        font-size: 4vw;
    }
    
    .contImg {
        width: 83vw;
        margin-bottom: 3vw;
    }
    
    .mensajeEdit {
        width: 85vw;
        height: 10vw;
    }
    
    .actualizar {
        font-size: 3vw;
        margin-left: 3vw;
    }
    
    .contVideo {
        width: 83vw;
        margin-bottom: 3vw;
    }
    
    .userOrigen {
        margin-left: 7vw;
        margin-right: 5vw;
        margin-top: 4vw;
        margin-bottom: 2vw;
    }
    
    .contMsgOrigen {
        margin-left: 7vw;
        margin-right: 7vw;
        margin-bottom: 3vw;
    }
    
    .contCompartido {
        margin-left: 2vw;
    }
    
    .contReaction {
        width: 83vw;
        height: 35px;
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 10px;
    }
    
    .contR {
        width: 50vw;
        height: 5vw;
        margin-top: 2vw;
    }
    
    .contM {
        width: 33vw;
        margin-bottom: 2vw;
    }
    
    .reactText {
        font-size: 4vw;
    }
    
    .icon {
        width: 4vw;
        height: 4vw;
        margin-right: 2vw;
        margin-top: 1vw;
    }
    
    .medallas {
        bottom: 8vw;
        right: 5vw;
        width: 35vw;
        height: 10vw;
        margin-bottom: 2vw;
        padding-left: 1vw;
        padding-right: 2vw;
    }
    
    .contMedalla {
        width: 9vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }
    
    .medalla {
        width: 9vw;
        height: 9vw;
    }
    
    .comentarios {
        width: 88vw;
        margin-left: 0px;
        height: 70vh;
    }
    
    .Contcompartir {
        margin-left: 3vw;
        margin-right: 3vw;
        width: 88vw;
        height: 40vw;
    }
    
    .msgComent {
        width: 88vw;
    }
    
    .contComent {
        margin-top: 3vw;
        width: 75vw;
        margin-left: 1vw;
    }
    
    .inp {
        width: 300px;
        height: 60px;
        border-radius: 10px;
        background-color: #1C1D1E;
        margin-left: 24px;
        margin-right: 20px;
        margin-bottom: 10px;
        color: #FFFFFF;
    }
    
    .btn {
        width: 67vw;
        height: 10vw;
        margin-left: 6vw;
        margin-right: 3.5vw;
        margin-bottom: 4vw;
        font-size: 4vw;
    }
    
    .contComentarios {
        height: 40vh;
        border-radius: 10px;
    }
    
    .textarea {
        width: 65vw;
        height: 15vw;
        margin-left: 0px;
        margin-right: 4vw;
    
        color: #FFFFFF;
    }
    
    .charCount {
        font-size: 3vw;
        margin-bottom: 2vw;
        margin-right: 5vw;
    }
    
    .fotoPubli {
        width: 8vw;
        height: 8vw;
        margin-right: 2vw;
        margin-bottom: 15vw;
    }

    .contAplausos{
        background-color: rgb(29, 28, 28);
        border: none;
    }

    .cerrar{
        display: none;
    }

    .iconBack{
        display: block;
        width: 30px;
        height: 30px;
        margin-right: auto;
        margin-top: 12vh;
        margin-left: 3vw;
    }

    .secontContList{
        width: 100%;
        height: 100%;
        z-index: 3;
        align-items: center;
    }

    .contAplauso{
        margin-left: 2vw;
        margin-right: 2vw;
        width: 96vw;
    }

    .contRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }

    .txtA{
        margin-right: 5px;
    }

    .iconM{
        width: 50px;
        height: 50px;
        margin-right: 0px;
    }

    .iconMm{
        width: 40px;
        height: 40px; 
    }

    .reactTextM{
        margin-right: 30px;
    }

    .btnAdd{
        width: 100px;
        margin-left: 10px;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    .compartir {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 12px;
        padding: 24px;
        background-color: rgba(0, 0, 0, 0.218);
    }
    
    .recomendarCont {
        display: flex;
        flex-direction: column;
        margin-top: 4px;
    }
    
    .contInfo:hover {
        cursor: pointer;
    }
    
    .infoIcon {
        width: 15px;
        height: 15px;
    }
    
    .infoName {
        margin-left: 6px;
        color: rgb(255, 255, 255);
        font-size: 12px;
    }
    
    .recomendar {
        display: flex;
        flex-direction: row;
        padding-right: 12px;
        padding-left: 12px;
        border-radius: 10px;
    }
    
    
    
    .delete {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    
    .comentario {
        width: 87vw;
        height: 20vw;
    }
    
    .commentUser {
        width: 85vw;
    }
    
    .comentCont {
        width: 67vw;
        height: 15vw;
        margin-left: 5vw;
        margin-bottom: 3vw;
    }
    
    .userComnt {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    .userIcon {
        width: 50px;
        height: 50px;
        border-radius: 50px;
    }
    
    .userName {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 5px;
        margin-left: 15px;
    }
    
    .fecha {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 12px;
        color: white;
        margin-right: 12px;
    }
    
    .commentText {
        color: rgb(255, 255, 255);
        margin-left: 15px;
    }
    
    
    
    .userImg {
        width: 50px;
        height: 50px;
        border-radius: 100px;
    }
    
    .reactTextB {
        font-family: Oswald;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.71px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 9px;
    }
    
    .reactTextC {
        font-family: Oswald;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.71px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 11px;
        margin-left: 5px;
    }

    .reactTextM {
        font-family: Oswald;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.71px;
        text-align: left;
        color: #FFFFFF;
        margin-top: 11px;
        margin-left: 5px;
    }
    
    .reactTextTrue {
        font-family: Oswald;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.71px;
        text-align: left;
        color: #E4FD3F;
        animation: bounce;
        animation-duration: 2s;
    }
    
    .iconT {
        width: 45px;
        height: 30px;
        margin-top: 10px;
    }
}