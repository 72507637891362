.conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    border-radius: 10px;
    margin-right: 3vw;
    width: 100%;
}

.posicion {
    display: flex;
    flex-direction: column;
    width: 30vw;
    z-index: 2;
}

.contTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 30vw;
    height: 30px;
    margin-bottom: 15px;
}

.seccion {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.btnTxt {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #E4FD3F;
    align-self: end;
}

.btnTxt:hover {
    color: #FFFFFF;
    cursor: pointer;
}

.campo {
    width: 300px;
    height: 130px;
    margin-bottom: 20px;
    margin-left: 80px;
}

.contPosicion {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 30vw;
}

.title {
    font-family: Oswald;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.posi {
    width: 167px;
    height: 30px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background-color: #262626;
    margin-left: 10px;
    margin-right: 10px;
}

.txt {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
}

.habilidades {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin-top: 10px;
    z-index: 2;
}

.contHabilidades {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    width: 30vw;
    flex-wrap: wrap;
}

.habi {
    flex-basis: calc(33.33% - 20px);
    height: 30px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background-color: #262626;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.metricas {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin-top: 10px;
    z-index: 2;
}

.radar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/GraficoBase.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 275px 325px;
    width: 30vw;
    height: 300px;
    padding-left: 32px;
    padding-bottom: 30px;
}

.cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
    height: 35px;
    margin-bottom: 20px;
}

.btnRegresar {
    width: 220px;
    height: 30px;
    border-radius: 8px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
}

.btnRegresar:hover {
    background-color: #444444;
    cursor: pointer;
}

.btnContinuar {
    width: 220px;
    height: 30px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-left: 25px;
    margin-top: 15px;
}

.btnContinuar:hover {
    background-color: #FFFFFF;
    cursor: pointer;
}

.contInp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.inputCampo {
    width: 180px;
    height: 30px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
}

.subT {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.contOpciones {
    display: flex;
    flex-direction: column;
    width: 636px;
    height: 232px;
}

.opciones{
    display:grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    justify-content: space-around;
    width: 30vw;
    height: 232px;
}

.opcionh{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.contCheck {
    display: flex;
    flex-direction: row;
}

.checkbox {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 5px;
    background-color: #262626;
    cursor: pointer;
}

.labelCheckbox {
    font-size: 12px;
    color: #333;
}

.textCheck {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    margin-right: 4px;
}

.contCalificacion{
    width: 30vw;
    height: 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.selectOpcM {
    font-family: Oswald;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.23px;
    text-align: left;
    color: #FFFFFF;
    /* margin-bottom: 5px; */
}

.textInfoM {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.61px;
    /* margin-bottom: 5px; */
}

.contCali{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30vw;
    height: 20px;
}

.caliSeleccionado{
    display: flex;
    width: 120px;
    height: 20px;
    border-radius: 6px;
    background-color: #E4FD3F;
    align-items: center;
    justify-content: center;
    
}

.cali{
    display: flex;
    width: 80px;
    height: 20px;
    border-radius: 6px;
    background-color: #262626;
    align-items: center;
    justify-content: center;
    /* margin-right: 10px; */
}

.cali:hover{
    cursor: pointer;
    background-color: #444444;
}

.caliText{
    color: #FFFFFF;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.75px;
    text-align: left;    
}

.sensura{
    width: 30vw;
    border-radius: 12px;
}

.tab{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 30vw;
    height: 30px;
}

.tabItem{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #262626;
}

.tabItem:hover{
    cursor: pointer;
    background-color: #444444;
}

.tabItemActive{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #404040;
}

.tabText{
    color: #FFFFFF;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.75px;
    text-align: left;
}

.scoreChart{
    width: 100%;
    height: 300px;
}

.rangoFechas {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;
    gap: 20px;
}

.fechaInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fechaInput label {
    color: white;
    font-size: 14px;
  }
  
  .fechaInput input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgba(228, 253, 63, 0.37);
    background: transparent;
    color: white;
  }

  .fechaInput input:focus {
    outline: none;
    border-color: rgba(228, 253, 63, 1);
  }

@media screen and (max-width: 767px) {
    .conteiner{
        width: 94vw;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10vh;
    }
    .posicion{
        width: 94vw;
    }
    .contTitle{
        width: 94vw;
        height: 7vw;
        margin-top: 3vw;
        margin-bottom: 3vw;
    }
    .seccion{
        font-size: 5vw;
        margin-bottom: 2vw;
    }
    .btnTxt{
        font-size: 4vw;
        margin-right: 2vw;
    }
    .campo{
        width: 94vw;
        height: 40vw;
        margin-left: 0px;
    }
    .title{
        font-size: 4vw;
    }
    .contPosicion{
        width: 94vw;
        margin-bottom: 2vw;
    }
    .posi{
        width: 50vw;
        height: 8vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .txt{
        font-size: 3vw;
        line-height: 0px;
    }
    .habilidades{
        width: 94vw;
    }
    .contHabilidades{
        width: 94vw;
        margin-bottom: 2vw;
    }
    .metricas{
        width: 94vw;
        margin-top: 2vw;
    }
    .radar{
        width: 90vw !important;
        height: 45vh !important;
        box-sizing:content-box !important;
        display: flex;
        background-size: 47vw 30vh;
        padding-left: 7vw;
        padding-bottom: 7vw;
    }
    .cont{
        width: 94vw;
        height: auto;
        margin-bottom: 5vw;
        flex-direction: column-reverse;
    }
    .btnRegresar{
        width: 94vw;
        height: 8vw;
        margin-top: 2vw;
        font-size: 4vw;
    }
    .btnContinuar{
        width: 94vw;
        height: 8vw;
        margin-top: 2vw;
        font-size: 4vw;
        margin-left: 0px;
    }
    .subT{
        font-size: 3vw;
    }
    .inputCampo{
        width: 45vw;
        margin-right: 5vw;
    }
    .contInp{
        margin-bottom: 2vw;
    }
    .contOpciones{
        width: 90vw;
        height: auto;
    }
    .opciones{
        width: 90vw;
        height: auto;
    }
    .opcionh{
        margin: 1vw;
    }
    .checkbox{
        width: 4vw;
        height: 4vw;
    }
    .textCheck{
        font-size: 3.5vw;
        margin-right: 1vw;
    }
    .contCalificacion{
        width: 96vw;
        margin-bottom: 4vw;
        height: auto;
    }
    .selectOpcM{
        font-size: 3.5vw;
    }
    .textInfoM{
        font-size: 3vw;
    }
    .contCali{
        width: 96vw;
    }
    .cali{
        width: 17vw;
        margin-right: 0px;
    }
    .caliSeleccionado{
        width: 19vw;
        margin-right: 0px;
        .caliText{
            color: #1C1D1E;
        }
    }
    .caliText{
        font-size: 4vw;
    }
    .tab{
        width: 50vw;
    }
    .tabItem{
        width: 50%;
    }
    .tabItemActive{
        width: 50%;
    }
    .tabText{
        font-size: 12px;
        line-height: 15.75px;
    }
}