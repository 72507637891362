.container {
    /* background-color: rgb(9, 118, 100); */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 16vh;
}

.amigos {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: #262626;
    width: 355px;
    height: 550px;
    scrollbar-width: none;
    margin-right: 100px;
    margin-left: 100px;
}

.amigosOculto {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: #262626;
    width: 355px;
    height: 550px;
    scrollbar-width: none;
    margin-right: 100px;
    margin-left: 100px;
}

.lista {
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 400px;
}


.contAmi {
    list-style: none;
    width: 330px;
    border-radius: 8px;
    margin-top: 10px;
}

.destinatarioSeleccionado {
    background-color: #3b3b3b;
}

.contAmi:hover {
    cursor: pointer;
    background-color: #3b3b3b;
}

.user {
    display: flex;
    flex-direction: row;
    align-content: flex-start;

}

.foto {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid #E4FD3F;
    margin-left: 5px;
}

.fotoB {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
    border: 2px solid #E4FD3F;
    margin-left: 5px;
}

.bandeja {
    display: flex;
    flex-direction: column;
    width: 700px;
    border-radius: 10px;
}

.bandejaMensaje {
    align-self: center;
}

.containerMessage {
    display: flex;
    flex-direction: column;
    width: 700px;
    height: 100%;
}

.userHistory {
    background-color: #262626;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 680px;
    height: 110px;
    border-radius: 12px;
    padding: 10px;
}

.contName {
    width: 390px;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.name {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
}

.posicion {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.line {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgb(19, 87, 67);
    margin-left: 20px;
    font-size: 16px;
}

.messages {
    width: 700px;
    height: 400px;
    margin-top: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.msgRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.msgRowA {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.listMsg {
    width: 350px;
    height: 100px;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.listMsgA {
    width: 350px;
    height: 100px;
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 335px;
    margin-bottom: 15px;
}

.contMsg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contMsgA {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.conMen {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #262626;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.conMenA {
    display: flex;
    flex-direction: column;
    width: 280px;
    height: 100px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #E4FD3F;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.txt {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    color: #FFFFFF;
}

.txtA {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: right;
    color: #000000;
}

.envio {
    display: flex;
    flex-direction: column;
    background-color: #262626;
    width: 700px;
    height: 170px;
    align-self: center;
    margin-bottom: 10px;
    border-radius: 12px;
}

.contTextArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
}

.cuadroTxt {
    width: 580px;
    height: 60px;
    border-radius: 10px;
    background-color: #0F0F0F;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    padding: 5px;
    border: none;
}

.cuadroTxt::placeholder {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #A4A4A4;
}

.btn {
    width: 5vw;
    height: 3vh;
    border-radius: 5px;
    font-size: 16px;
    margin-left: 1vw;
    background-color: white;
    border: none;
    color: rgb(31, 95, 90);
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.leido {
    margin-left: 4vw;
    font-size: 12px;
    color: rgb(255, 255, 255);
}

.infoMsg {
    display: flex;
    flex-direction: column;

}

.nombre {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    color: #FFFFFF;
}

.mensajesNum {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.estado {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 250px;
}

.inLine {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: rgb(69, 65, 65);
    margin-left: 6px;
}

.nameMsg {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 22px;
    color: rgb(31, 95, 90);
}

.conTitle {
    display: flex;
    width: 320px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 15px;
}

.title {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.avisoA {
    color: #FFFFFF;
}

.icon {
    width: 30px;
    height: 30px;
}

.iconB {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.iconC {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.buscador {
    width: 290px;
    height: 46px;
    border-radius: 8px;
    background-color: #262626;
    margin-bottom: 18px;
    padding-left: 35px;
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
}

.buscador::placeholder {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #A3A3A3;
}

.time {
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.52px;
    text-align: left;
    color: #A4A4A4;
}

.msjPreview {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 6px;
}

.contInp {
    position: relative;
}

.iconoContainer {
    position: absolute;
    top: 40%;
    left: 15px;
    transform: translateY(-50%);
}

.icono {
    width: 16px;
    height: 16px;
}

.btnV {
    width: 90px;
    height: 36px;
    background-color: #0F0F0F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 25px;
    margin-left: 50px;
    cursor: pointer;
}

.contBtn {
    width: 650px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 5px;
}

.btnC {
    width: 320px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #0F0F0F;
    border-radius: 6px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
}

.btnE {
    width: 320px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #E4FD3F;
    border-radius: 6px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    color: #0F0F0F;
}

.btnC:hover{
    cursor: pointer;
    opacity: .7;
}

.btnE:hover{
    cursor: pointer;
    opacity: .7;
}

.back {
    display: none;
}

@media screen and (max-width: 767px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        margin-top: 10vh;
    }
    
    .amigos {
        width: 98vw;
        height: 80vh;
        margin-right: 0px;
        margin-left: 0px;
        border-radius: 0px;
        background-color: transparent;
    }

    .amigosOculto{
        display: none;
    }
    
    .lista {
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 0px;
        background-color:#262626;
        border-radius: 8px;
        padding-top: 3vw;
        padding-bottom: 3vw;
        height: 65vh;
    }
    
    .contAmi {
        width: 85vw;
        margin: 3vw;
    }
    
    .user {
        display: flex;
        flex-direction: row;
        width: 85vw;
    }
    
    .foto {
        width: 15vw;
        height: 15vw;
        margin-right: 2vw;
        margin-left: 1vw;
    }
    
    .fotoB {
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
        margin-left: 1vw;
    }
    
    .bandeja {
        display: flex;
        flex-direction: column;
        width: 98vw;
        border-radius: 10px;
    }
    
    .bandejaMensaje {
        align-self: center;
    }
    
    .containerMessage {
        width: 100vw;
        height: 90vh;
    }
    
    .userHistory {
        width: 98vw;
        height: 12vh;
        padding: 1vw;
        border-radius: 0px;
    }
    
    .contName {
        width: 45vw;
        margin-left: 1vw;
    }
    
    .name {
        font-size: 5vw;
    }
    
    .posicion {
        font-size: 3vw;
    }
    
    .line {
        margin-left: 5vw;
        font-size: 4vw;
    }
    
    .messages {
        width: 96vw;
        height: 50vh;
        margin-top: 2vw;
    }
    
    .listMsg {
        width: 50vw;
        height: 12vh;
        margin-bottom: 1vw;
    }
    
    .listMsgA {
        width: 50vw;
        height: 12vh;
        margin-left: 45vw;
        margin-bottom: 4vw;
    }
    
    .contMsg {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .contMsgA {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    
    .conMen {
        width: 48vw;
        height: 11vh;
        padding: 2vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
    }
    
    .conMenA {
        width: 48vw;
        height: 11vh;
        padding: 2vw;
        margin-top: 2vw;
        margin-bottom: 1vw;
    }
    
    .txt {
        font-size: 4vw;
    }
    
    .txtA {
        font-size: 4vw;
    }
    
    .envio {
        width: 96vw;
        height: 20vh;
        margin-bottom: 2vw;
    }
    
    .contTextArea {
        padding: 2vw;
    }
    
    .cuadroTxt {
        width: 75vw;
        height: 8vh;
        font-size: 4vw;
        padding: 1vw;
    }
    
    .cuadroTxt::placeholder {
        font-size: 4vw;
    }
    
    .btn {
        width: 5vw;
        height: 3vh;
        border-radius: 5px;
        font-size: 16px;
        margin-left: 1vw;
        background-color: white;
        border: none;
        color: rgb(31, 95, 90);
    }
    
    .leido {
        margin-left: 4vw;
        font-size: 12px;
        color: rgb(255, 255, 255);
    }

    
    .nombre {
        font-size: 4vw;
    }
    
    .mensajesNum {
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    
    .estado {
        width: 65vw;
    }
    
    .inLine {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background-color: rgb(69, 65, 65);
        margin-left: 6px;
    }
    
    .nameMsg {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-size: 22px;
        color: rgb(31, 95, 90);
    }
    
    .conTitle {
        width: 90vw;
        margin: 0px;
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    
    .title {
        font-family: Oswald;
        font-size: 20px;
        font-weight: 500;
        line-height: 29.64px;
        text-align: left;
        color: #FFFFFF;
    }
    
    .avisoA {
        color: #FFFFFF;
    }
    
    .icon {
        width: 8vw;
        height: 8vw;
    }
    
    .iconB {
        width: 10vw;
        height: 4vh;
        margin-right: 5px;
    }
    
    .buscador {
        width: 80vw;
        height: 5vh;
        margin-bottom: 5vw;
        padding-left: 9vw;
        font-size: 4vw;
    }
    
    .time {
        font-size: 3vw;
    }
    
    .msjPreview {
        font-size: 3vw;
        margin-top: 2vw;
    }
    
    .contInp {
        position: relative;
        width: 90vw;
    }
    
    .iconoContainer {
        left: 4vw;
    }
    
    .icono {
        width: 4vw;
        height: 4vw;
    }
    
    .btnV {
        width: 20vw;
        height: 5vh;
        font-size: 4vw;
        margin-right: 5vw;
        margin-left: 0px;
    }
    
    .contBtn {
        width: 94vw;
        margin: 2vw;
    }
    
    .btnC {
        width: 12vw;
        height: 5vh;
        font-size: 0px;
    }
    
    .btnE {
        width: 75vw;
        margin-right: 3vw;
        height: 5vh;
        font-size: 4vw;
    }

    .iconC {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }

    .back {
        display: flex;
        font-family: Raleway;
        font-size: 4vw;
        font-weight: 600;
        line-height: 17.61px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 2vw;
        margin-top: 3vw;
        margin-left: 3vw;
    }
}