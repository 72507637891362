.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subContainer{
    padding-top: 12vh;
}
.feed{
    margin-top: 20px;
}