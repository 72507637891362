.conteiner {
    width: 450px;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    margin-top: 10px;
}

.contTitle {
    width: 430px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.title {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.contVideo {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    width: 386px;
}

.contNoticia {
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #262626;
    border-radius: 12px;
    margin-bottom: 20px;
}

.contInfo {
    width: 390px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
    margin-top: 15px;
    margin-bottom: 12px; 
    margin-left: 20px;
    margin-right: 20px; 
}

.contMarca {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.marcaLogo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.marcaInfo {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.marcaNombre {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.marcaLink {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.time {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: right;
    color: #A4A4A4;
}

.noticia{
    width: 422px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 35px; 
}

.mensaje{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;   
    margin-bottom: 12px;   
}

.imgNoticia{
    width: 390px;
    height: 210px;
    border-radius: 12px;
}

.contReaction {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 35px;
    justify-content: left;
    padding-bottom: 10px;
}

.contR {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    width: 250px;
    height: 20px;
    margin-top: 10px;
}

.contM{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: flex-start;
    width: 250px;
    height: 20px;
    margin-top: 14px; 
}

.recomendarCont {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-right: 10px;
}

.recomendar {
    display: flex;
    flex-direction: row;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 10px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-top: 5px;
}

.iconB {
    margin-left: 10px;
    width: 45px;
    height: 32px;
    margin-right: 5px;
}

.reactText {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
}