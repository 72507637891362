.nav {
    display: flex;
    width: 1200px;
    height: 80px;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(33, 33, 33);
    z-index: 9999;
    border-radius: 20px;
    margin-top: 2vh;
  }
  .navLinksA {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
  }
  .navLinksB {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 300px;
  }
  .navLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 900px;
  }
  
  .btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;
    cursor: pointer;
  }
  
  .btn:hover {
    cursor: pointer;
    color: #E4FD3F;
    .icon{
      filter: brightness(1) invert(40%) sepia(5) saturate(50) hue-rotate(45deg);
    }
    .aviso{
      display: none;
    }
    .a{
      color: #E4FD3F;
    }
  }
  
  .btnExit {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .btnExit:hover {
    cursor: pointer;
    filter: invert(100%);
  }
  
  .part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .icon {
    height: 5vh;
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .iconExit {
    height: 5vh;
    padding-left: 5px;
    padding-right: 25px;
  }
  
  .contMnesaje{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
  
  .aviso {
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(144, 29, 29);
    margin-left: 6px;
    position: absolute;
    font-size: 10px;
    color: white;
    justify-content: center;
  }
  .a{
    color: white;
    font-size: 12px;
  }
  .aExit{
    color: rgb(65, 255, 65);
    font-size: 12px;
  }