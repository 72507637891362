#app-container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh; 
  /* aproved */
}

@media screen {
  #app-container {
    width: 100vw;
  }
}
