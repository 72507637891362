.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #0F0F0F;
    background-image: url("../../assets/gradiente.png");
    background-repeat: no-repeat;
    background-size: 100% 40%;
}

.nav {
    width: 100vw;
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.navAnd {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: 20px;
}

.logoAnd {
    width: 150px;
    height: 40px;
    margin-top: 0px;
    margin-left: 20px;
}

.cont {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.contSobre{
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.logo {
    width: 180px;
    height: 70px;
    padding-left: 0px;
    padding-top: 30px;
}

.logoP {
    width: 150px;
    height: 40px;
    padding-left: 10px;
    padding-top: 30px;
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-top: 30px;
}

.menuP {
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-between;
    gap: 10px;
    padding-top: 30px;
}

.btn {
    display: flex;
    height: 96px;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}

.btn:hover {
    cursor: pointer;
    color: #E4FD3F;
    border-bottom: 3px solid #E4FD3F;
}

.btn p {
    font-family: Oswald;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
}


.btnRegistro {
    display: flex;
    width: 224px;
    height: 46px;
    border-radius: 8px;
    color: black;
    background-color: #E4FD3F;
    align-items: center;
    justify-content: center;
    margin-right: 164px;
    margin-top: 30px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;

}

.btnRegistro:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}


.comunidad {
    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    background-image: url("../../assets/fondoComunidad.png");
    padding-top: 80px;
    padding-left: 160px;
    padding-bottom: 5px;
    padding-right: 130px;
}

.contDato {
    display: flex;
    flex-direction: column;
}

.subT {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 600;
    line-height: 62.24px;
    text-align: center;
    color: #000000;
}

.contNum {
    display: flex;
    flex-direction: row;
    width: 620px;
    justify-content: space-between;
}

.jugadores {
    display: flex;
    flex-direction: column;
    width: 280px;
}

.num {
    font-family: Oswald;
    font-size: 96px;
    font-weight: 600;
    line-height: 142.27px;
    text-align: left;
    color: #000000;
}

.jugadoresTxt {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    color: #000000;
}

.botones {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
}

.contImagesC {
    position: relative;
    display: flex;
    width: 650px;
    height: 500px;
    margin-left: 100px;
}

.imgAppFive {
    width: 150px;
    height: 303px;
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 350px;
}

.imgFour {
    width: 437px;
    height: 339px;
    border-radius: 12px;
}

.btnRegis {
    width: 158px;
    height: 46px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
    margin-right: 30px;
}

.btnRegis:hover {
    cursor: pointer;
    background-color: #000000;
    color: #FFFFFF;
}

.btnIniciar {
    width: 158px;
    height: 46px;
    background-color: #000000;
    ;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
}

.btnIniciar:hover {
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
}

.pie {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #000000;
    padding-top: 30px;
    padding-left: 160px;
    padding-right: 150px;
    padding-bottom: 20px;
}

.contMenu {
    display: flex;
    flex-direction: row;
    width: 80vw;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}

.logo:hover {
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: row;
    width: 355px;
    justify-content: space-between;
}

.btnM {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
}

.btnM:hover {
    cursor: pointer;
    color: #E4FD3F;
    border-bottom: 3px solid #E4FD3F;
}

.redes {
    display: flex;
    flex-direction: row;
    width: 12vw;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.iconR {
    width: 30px;
    height: 30px;
}

.iconR:hover {
    cursor: pointer;
    filter: invert(10%) sepia(100%) saturate(10000%);
}

.contContacto {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.dataContact {
    display: flex;
    flex-direction: column;
    width: 257px;
    height: 181px;
    margin-right: 130px;
}

.contContac {
    width: 127px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E4FD3F;
    border-radius: 7px;
    margin-bottom: 27px;
}

.contContac:hover {
    cursor: pointer;
    background-color: #e4fd3fb8;
}

.txtContac {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: center;
    color: #000000;
}

.contact {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.mailing {
    width: 720px;
    height: 181px;
    display: flex;
    flex-direction: row;
    background-color: #292A32;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
}

.inpMail {
    width: 285px;
    height: 65px;
    border-radius: 14px;
    background-color: transparent;
    padding-left: 22px;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 20px;
    border: 1px solid #FFFFFF;
}

.inpMail::placeholder {
    color: #FFFFFF;
}

.btnMail {
    width: 323px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E4FD3F;
    border-radius: 14px;
}

.btnMail:hover {
    cursor: pointer;
    background-color: #00000023;

    .txtMail {
        color: #FFFFFF;
    }
}

.txtMail {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.copyright {
    display: flex;
    width: 100vw;
    justify-content: left;
    margin-top: 100px;
    margin-left: 400px;
    align-items: center;
}

.copyTxt {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.menu-lateral{
    display: none;
}

.iconMenu{
    display: none;
}

.btnPolitica{
    color: #E4FD3F;
    cursor: pointer;
}

@media screen and (max-width: 767px) {

    .container {
        width: 100%;
    }

    .nav {
        position: fixed;
        /* Fija la barra de navegación en la parte superior */
        top: 0vw;
        left: 0;
        height: auto;
        width: 100vw;
        transition: background-color 0.3s;
        justify-content: left;
        z-index: 999;
        padding-top: 7vw;
        padding-bottom: 7vw;
    }

    .nav-scrolled {
        background-color: rgb(15, 15, 15);
        /* Cambia este color según tu diseño */
    }

    /* Estilo para el contenedor del menú lateral */
    .menu-lateral {
        display: block;
        position: fixed;
        top: 0;
        left: -250px;
        /* Inicialmente oculto, ajuste el valor según el ancho del menú */
        width: 250px;
        /* Ancho del menú lateral */
        height: 100%;
        background-color: rgb(15, 15, 15);
        /* Color de fondo del menú */
        color: #fff;
        transition: left 0.3s;
        /* Transición suave para el desplazamiento */
        z-index: 1000;
        padding-top: 10vw;
    }

    /* Estilo para el menú visible */
    .menu-lateral.show {
        left: 0;
        /* Muestra el menú cuando se agrega la clase 'show' */
    }

    /* Estilo para los enlaces del menú */
    .menu-lateral a {
        display: block;
        padding: 15px;
        text-decoration: none;
        color: #fff;
        margin-left: 5vw;
        font-size: 5vw;
        margin-top: 2vw;
    }

    .menu-lateral a:hover {
        background-color: #444;
        /* Color de fondo del enlace en hover */
    }

    .iconMenu{
        display: block;
        width: 12vw;
        height: 12vw;
        margin-left: 10vw;
    }

    .cont {
        margin-top: 10vw;
    }

  

    .logo {
        padding-left: 0px;
        padding-top: 0px;
        margin-right: 10vw;
        width: 30vw;
        height: 10vw;
        margin-left: 15vw;
    }

    .logoP {
        padding-left: 0px;
        padding-top: 0px;
        margin-right: 0px;
        width: 18vw;
        height: 6vw;
        margin-left: 5vw;
    }

    .menu {
        padding-top: 0px;
        width: 35vw;
        margin-right: 7vw;
        display: none;
    }

    .btn {
        height: auto;
        font-weight: 500;
        line-height: 12px;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
    }

    .btn p {
        font-size: 3vw;
        font-weight: 500;
        line-height: 3vw;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;
    }

    .btnRegistro {
        width: 20vw;
        height: 7vw;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 3px;
        font-size: 3vw;
        font-weight: 600;
        line-height: 12.61px;
        text-align: center;
    }

    .comunidad {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15vw;
        padding-bottom: 15vw;
    }

    .contImagesC {
        display: none;
    }

    .contDato {
        width: 100vw;
        align-items: center;
    }

    .contNum {
        width: 90vw;
    }

    .pie {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 15vw;
        padding-bottom: 5vw;
    }

    .contMenu {
        width: 100vw;
        margin-bottom: 15vw;
    }

    .menuP {
        display: none;
    }

    .contContacto {
        flex-direction: column;
        width: 80vw;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .dataContact {
        width: 80vw;
        margin-right: 0px;
        margin-left: 0px;
    }

    .mailing {
        width: 80vw;
        flex-direction: column;
        margin-top: 10vw;
    }

    .inpMail {
        width: 70vw;
        height: 10vw;
        margin-right: 0px;
        margin-bottom: 7vw;
        font-size: 3vw;
        padding-left: 3vw;
    }

    .btnMail {
        width: 75vw;
        height: 10vw;
    }

    .txtMail {
        font-size: 4vw;
        line-height: 4vw;
        font-weight: 600;
    }

    .subT {
        font-size: 12vw;
        line-height: 12vw;
    }

    .jugadores {
        width: 50%;
        align-items: center;
    }

    .num {
        font-size: 10vw;
        line-height: 30vw;
    }

    .jugadoresTxt {
        text-align: center;
        font-size: 5vw;
        line-height: 7vw;
    }

    .botones {
        margin-top: 15vw;
    }

    .btnRegis {
        width: 40vw;
        height: 10vw;
        font-size: 4vw;
    }

    .btnIniciar {
        width: 40vw;
        height: 10vw;
        font-size: 4vw;
    }

    .btnM {
        font-size: 3vw;
        line-height: 4vw;
        text-align: center;
    }

    .redes {
        width: 30vw;
        margin-right: 5vw;
    }

    .contContac {
        margin-bottom: 0px;
        width: 80vw;
        margin-bottom: 5vw;
    }

    .txtContac {
        font-size: 4vw;
    }

    .contact {
        font-size: 3vw;
        margin-bottom: 5vw;
    }

    .copyright {
        margin-top: 15vw;
        margin-left: 0px;
        justify-content: center;
    }

    .copyTxt {
        font-size: 3vw;
    }

    .iconR {
        width: 6vw;
        height: 6vw;
    }
}