.conteiner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 999;
}

.modalContent {
  display: flex;
  position: relative;
  width: 85%;
  max-width: 1000px;
  height: 80vh;
  background-image: url("../../assets/fondoLimpio.png");
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 5vh;
}

.modalContentAnd {
  display: flex;
  position: relative;
  width: 85%;
  max-width: 1000px;
  height: 80vh;
  background-image: url("../../assets/fondoLimpio.png");
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 5vh;
}

.contGrafica {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  position: relative;
}

.contTitle {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-left: 37px;
}

.iconFuerza {
  width: 52px;
  height: 52px;
  margin-right: 20px;
}

.title {
  font-family: Oswald;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  text-align: left;
  color: #ffffff;
  margin-top: 5px;
}

.contInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 37px;
  margin-top: 0px;
  width: 400px;
}

.infoC {
  display: flex;
  flex-direction: column;
}

.contData {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.sub {
  font-family: Oswald;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.66px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 4px;
}

.data {
  font-family: Oswald;
  font-size: 32.29px;
  font-weight: 400;
  line-height: 26.29px;
  text-align: left;
  color: #ffffff;
  margin-right: 3px;
}

.unidad {
  font-family: Raleway;
  font-size: 26.66px;
  font-weight: 400;
  line-height: 31.3px;
  text-align: center;
  color: #ffffff;
}

.contFecha {
  display: flex;
  flex-direction: column;
}

.contSubfecha {
  display: flex;
  flex-direction: column;
}

.subFecha {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: right;
  color: #ffffff;
  margin-bottom: 2px;
}

.fecha {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.09px;
  text-align: right;
  color: #ffffff;
}

.contEdit {
  display: flex;
  flex-direction: row;
}

.titEdit {
  font-family: Oswald;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.64px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
}

.avisoG {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: left;
  color: white;
}

.contAviso {
  display: flex;
  width: 350px;
  margin-top: 40px;
}

.avisoH {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: left;
  color: white;
}

.subir {
  width: 300px;
  height: 100px;
  margin-top: 25px;
  cursor: pointer;
}

.contVideo {
  display: flex;
  width: 350px;
  height: 250px;
  background-color: white;
  border-radius: 10px;
}

.checkboxP {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #262626;
  background-color: #262626;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
}

.checkboxP:checked {
  background-color: #e4fd3f;
  /* border-color: #f5f5f5; */
  border: 4px solid rgb(62, 62, 62);
}

.contOption {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  align-items: center;
}

.inputext {
  /* margin-bottom: 8px; */
  display: none;
}

.slash {
  margin-right: 10px;
}

.txtSi {
  font-family: Raleway;
  font-size: 19px;
  font-weight: 500;
  line-height: 22.31px;
  text-align: center;
  color: white;
  margin-top: 5px;
}

.txtNo {
  font-family: Raleway;
  font-size: 19px;
  font-weight: 500;
  line-height: 22.31px;
  text-align: center;
  color: white;
  margin-top: 5px;
  margin-right: 100px;
}

.txtCerrar {
  font-family: Raleway;
  font-size: 19px;
  font-weight: 500;
  line-height: 22.31px;
  text-align: center;
  color: #e4fd3f;
  margin-left: 10px;
  cursor: pointer;
}

.txtEnviar {
  font-family: Raleway;
  font-size: 19px;
  font-weight: 500;
  line-height: 22.31px;
  text-align: center;
  color: #e4fd3f;
  cursor: pointer;
}

.txtCerrar:hover {
  color: #ffffff;
  cursor: pointer;
}

.contBtnG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.txtUnidad {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: left;
  color: white;
  margin-right: 50px;
  margin-top: 10px;
}

.btnG {
  width: 134px;
  height: 40px;
  background-color: #e4fd3f;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: center;
  color: #1c1d1e;
  margin-top: 18px;
  border-radius: 8px;
}

.btnGLoad {
  width: 134px;
  height: 40px;
  background-color: #4f504f;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: center;
  color: #ffffff;
  margin-top: 18px;
  border-radius: 8px;
}

.btnG:hover {
  cursor: pointer;
  background-color: #e4fd3fcc;
}

.conSubGrafico {
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  margin-top: 30px;
  width: 450px;
  height: 300px;
  position: relative;
}

.chartContainer {
  position: relative;
  width: 100%;
  height: 250px;
  margin-top: 20px;
}

.contHistorico {
  display: flex;
  flex-direction: column;
  width: 96vw;
  height: 50vh;
  justify-content: right;
  align-items: flex-end;
  margin-right: 15px;
  /* overflow-y: auto;
    scrollbar-width: none; */
}

.titleM {
  font-family: Oswald;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
  text-align: left;
  color: #ffffff;
  margin-top: 5px;
}

.titleMAnd {
  display: none;
}

.titleM:hover {
  color: #e4fd3f;
  cursor: pointer;
}

.contSubHistorico {
  display: flex;
  flex-direction: column;
}

.contMenu {
  display: flex;
  flex-direction: row;
  width: 362px;
  height: 35px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(38, 38, 38, 1);
  border-radius: 8px;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  margin-top: 20px;
}

.btn {
  width: 191px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.btnTxt {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.btn:hover {
  cursor: pointer;
  background-color: #ffffff;

  .btnTxt {
    color: rgba(28, 29, 30, 1);
  }
}

.contComparativo {
  display: flex;
  flex-direction: column;
  width: 396px;
  height: 450px;
  margin-top: 10px;
  overflow-y: auto;
  scrollbar-width: none;
}

.posicion {
  width: 380px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.iconM {
  width: 30px;
  height: 45px;
  margin-left: 5px;
  margin-top: 8px;
}

.contInfoUser {
  display: flex;
  flex-direction: row;
  width: 239px;
  justify-content: left;
  align-items: center;
  margin-left: 0px;
}

.userFoto {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: 2px solid rgba(228, 253, 63, 1);
  border-radius: 50%;
}

.infoUser {
  display: flex;
  flex-direction: column;
}

.nameUser {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 2px;
}

.info {
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.09px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.contDataP {
  display: flex;
  flex-direction: row;
  width: 50px;
  align-items: flex-end;
  margin-right: 5px;
}

.dataL {
  font-family: Oswald;
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.unidad {
  font-family: Raleway;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.61px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.posicionP {
  width: 380px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(38, 38, 38, 1);
  padding-top: 5px;
  padding-bottom: 5px;
}

.btnSelected {
  width: 191px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
}

.btnTxtSelected {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: left;
  color: rgba(28, 29, 30, 1);
}

.inpG {
  width: 190px;
  height: 40px;
  margin-right: 12px;
  margin-top: 12px;
  border-radius: 8px;
  background-color: #262626;
  color: #ffffff;
  border: 1px solid #a4a4a4;
}

.contEditBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contHistorial {
  width: 500px;
  height: 370px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  width: 396px;
  margin-top: 10px;
}

.numList {
  width: 30px;
  height: 45px;
  margin-top: 8px;
  margin-right: 20px;
  font-family: Oswald;
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  align-content: center;
  color: #ffffff;
}

@media screen and (max-width: 767px) {
  .conteiner {
    align-items: flex-start;
    padding-top: 75px;
    height: 100%;
    overflow-y: auto;
  }

  .modalContent {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 60px);
    margin: 0;
    border-radius: 0;
    flex-direction: column;
    position: relative;
  }

  .modalContentAnd {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 60px);
    margin: 0;
    border-radius: 0;
    flex-direction: column;
    position: relative;
  }

  .contGrafica {
    width: 100%;
  }

  .contGraficaDes {
    display: none;
  }

  .contTitle {
    margin-top: 3vw;
    margin-left: 3vw;
    width: 94vw;
  }

  .iconFuerza {
    width: 12vw;
    height: 12vw;
    margin-right: 5vw;
  }

  .title {
    font-size: 8vw;
    line-height: 8vw;
    margin-top: 1vw;
  }

  .contInfo {
    margin-left: 3vw;
    margin-top: 3vw;
    width: 94vw;
    flex-direction: column;
  }

  .sub {
    font-size: 4vw;
    margin-bottom: 1vw;
  }

  .data {
    font-size: 7vw;
    margin-right: 0px;
    line-height: 28px;
  }

  .dataL {
    font-size: 7vw;
  }

  .unidad {
    font-size: 3vw;
  }

  .subFecha {
    font-size: 4vw;
  }

  .fecha {
    font-size: 3vw;
  }

  .contEdit {
    display: flex;
    flex-direction: row;
    width: 96vw;
    margin-top: 7vw;
    margin-left: 0px;
    margin-bottom: 5vw;
    justify-content: center;
  }

  .titEdit {
    font-size: 5vw;
    margin-bottom: 1vw;
    margin-top: -5vw;
  }

  .avisoG {
    font-size: 3.5vw;
  }

  .contAviso {
    display: flex;
    width: 90vw;
    margin-top: 3vw;
  }

  .avisoH {
    font-size: 3.5vw;
  }

  .subir {
    width: 50vw;
    height: 10vh;
    margin-top: 3vw;
    cursor: pointer;
  }

  .contVideo {
    display: flex;
    width: 350px;
    height: 250px;
    background-color: white;
    border-radius: 10px;
  }

  .checkboxP {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #262626;
    background-color: #262626;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
  }

  .checkboxP:checked {
    background-color: #e4fd3f;
    /* border-color: #f5f5f5; */
    border: 4px solid rgb(62, 62, 62);
  }

  .contOption {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    align-items: center;
  }

  .inputext {
    /* margin-bottom: 8px; */
    display: none;
  }

  .slash {
    margin-right: 10px;
  }

  .txtSi {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: white;
    margin-top: 5px;
  }

  .txtNo {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: white;
    margin-top: 5px;
    margin-right: 100px;
  }

  .txtCerrar {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: #e4fd3f;
    margin-left: 10px;
    cursor: pointer;
  }

  .txtEnviar {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.31px;
    text-align: center;
    color: #e4fd3f;
    cursor: pointer;
  }

  .txtCerrar:hover {
    color: #ffffff;
    cursor: pointer;
  }

  .contBtnG {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0px;
    width: 92vw;
  }

  .txtUnidad {
    font-size: 4vw;
    margin-right: 11vw;
    margin-top: 2vw;
  }

  .btnG {
    width: 30vw;
    height: 10vw;
    font-size: 4vw;
    margin-top: 4vw;
    border-radius: 2vw;
  }

  .btnGLoad {
    width: 134px;
    height: 40px;
    background-color: #4f504f;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #ffffff;
    margin-top: 18px;
    border-radius: 8px;
  }

  .btnG:hover {
    cursor: pointer;
    background-color: #e4fd3fcc;
  }

  .conSubGrafico {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 0px;
    width: 94vw; /* Ajustar para móviles */
    height: auto; /* Ajustar para móviles */
  }

  .contHistorico {
    width: 100%;
    margin-left: 0px;
    height: auto;
    justify-content: right;
    align-items: flex-end;
    margin-right: 0px;
    overflow-y: clip;
    scrollbar-width: none;
  }

  .titleM {
    display: none;
  }

  .titleMAnd {
    display: flex;
    position: fixed;
    top: 20vw;
    right: 5vw;
    z-index: 1003;
    font-size: 6vw;
    font-weight: 600;
    text-align: center;
    color: #e4fd3f;
  }

  .contSubHistorico {
    display: flex;
    flex-direction: column;
    width: 98vw;
    align-items: center;
  }

  .contMenu {
    width: 90vw;
    height: 5vh;
    padding: 2vw;
    margin-top: 3vw;
  }

  .btn {
    width: 42vw;
    height: 9vw;
  }

  .btnTxt {
    font-size: 4vw;
  }

  .contComparativo {
    width: 96vw;
    height: 45vh;
    margin-top: 2vw;
  }

  .contComparativoDes {
    display: none;
  }

  .posicion {
    width: 94vw;
    height: 15vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  .iconM {
    width: 11vw;
    height: 14vw;
    margin-left: 0px;
    margin-top: 2vw;
  }

  .contInfoUser {
    width: 50vw;
    margin-left: 1vw;
  }

  .userFoto {
    width: 12vw;
    height: 12vw;
    margin-right: 3vw;
  }

  .infoUser {
    display: flex;
    flex-direction: column;
    width: 35vw;
  }

  .nameUser {
    font-size: 4vw;
    margin-bottom: 0px;
  }

  .info {
    font-size: 3.5vw;
  }

  .contDataP {
    width: 10vw;
    margin-right: 0px;
  }

  .posicionP {
    width: 94vw;
    height: 15vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }

  .btnSelected {
    width: 43vw;
    height: 9vw;
  }

  .btnTxtSelected {
    font-size: 4vw;
  }

  .inpG {
    width: 43vw;
    height: 10vw;
    margin-right: 2vw;
    margin-top: 2vw;
  }

  .contHistorial {
    width: 500px;
    height: 370px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
    width: 396px;
    margin-top: 10px;
  }

  .contHistorialDes {
    display: none;
  }

  .numList {
    width: 30px;
    height: 45px;
    margin-top: 8px;
    margin-right: 20px;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    align-content: center;
    color: #ffffff;
  }
}
