body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  
  background-image: url("./assets/FondoMain.png");
  background-attachment:local;
  background-position: center top 1px;
  background-repeat: no-repeat;
  background-size: 100vw 130vh;
  background-color: #0F0F0F;
  overflow-x: hidden;
}

@font-face {
  font-family: Raleway;
  src: url("./Font/static/Raleway-Light.ttf");
}

@font-face {
  font-family: Oswald;
  src: url("./Font/static/Oswald-Regular.ttf");
}

p {
  font-family: Raleway;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

span {
  font-family: Raleway;
}

h1 {
  font-family: Oswald;
  margin-block-start: 0px;
  margin-block-end: 0px;
}




h3{
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h4{
  margin-block-start: 0px;
  margin-block-end: 0px;
}

button {
  font-family: Raleway;
}

#elemento {
  width: 1200px; /* Ajusta el ancho según sea necesario */
  height: 1200px; /* Ajusta la altura según sea necesario */
  position:absolute; /* Permite posicionar el gradiente de manera absoluta */
  overflow: hidden; 
  /* Para ocultar el exceso del gradiente */
  margin-left: 20vw;
  margin-bottom: 50%;
}

@media screen and (max-width: 767px) {
  body{
    background-image: url("./assets/fondoLimpio.png");
    background-size: 100vw 100vh;
    background-attachment:local;
    background-position: center;
  }
}