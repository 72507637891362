.publicacionView{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20vh;
    width: 100%;
    height: 100%;
}

.contPublicacion{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.contUsuario{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 5%;
    width: 30%;
    height: 100%;
}

.publicacion{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40%;
    height: 100%;
}

.feed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    height: 100%;
    margin-right: 5%;
    margin-left: 5%;
}

.btnTxt{
    display: none;
}

.navSecond{
    display: none;
}


@media screen and (max-width: 767px) {
    .contUsuario{
        display: none;
    }
    .feed{
        display: none;
    }

    .publicacionView{
        margin-top: 10vh;
        height: 90vh;
        margin-left: 3%;
    }

    .navSecond{
        display: flex;
    }
    
    .btnTxt{
        display: block;
        color: white;
        font-size: 20px;
        font-family: Raleway;
    }
}