.conteiner {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    width: 96vw;
    margin-top: 96px;
    z-index: 1;
    margin-right: 2vw;
    margin-left: 2vw;
}

.partDesactive{
    width: 32vw;
}

.partOne{
    width: 23vw;
}

.partTwo{
    width: 35vw;
}

.partThree{
    width: 32vw;
}

.navSecond{
    display: none;
}

@media screen and (max-width: 767px) {
    .conteiner {
        flex-direction: column;
        margin-top: 15vh;
        padding-bottom: 30vh;
        width: 100vw;
        margin-right: 0px;
        margin-left: 0px;
        align-items: center;
    }

    /* Estilos para los componentes en móvil */
    .partOne,
    .partTwo,
    .partThree {
        width: 94vw;
    }

    /* Estado inicial para móvil */
    .partOne {
        display: block; /* Stadistics o Otro componente */
    }
    
    .partTwo {
        display: block; /* User o Listas */
    }
    
    .partThree {
        display: none; /* Feed inicialmente oculto */
    }

    /* Clase para componentes inactivos */
    .partDesactive {
        display: none;
    }

    /* Mostrar NavSecond en móvil */
    .navSecond {
        display: flex;
        width: 94vw;
    }

    /* Clases para estados activos específicos */
    .socialActive .partOne,
    .socialActive .partTwo {
        display: none;
    }

    .socialActive .partThree {
        display: block;
    }
}