
.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/Imagen\ Lateral\ Fondo.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 25vw 100vh;
}

.register{
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/FondoBack.png");
    background-position: center left 25vw;
    background-repeat: no-repeat;
    background-size: 75vw 100vh;
}

.contLogo{
    display: flex;
}

.logo{
    position: absolute;
    left: 55vw;
    top: 20px;
    width: 180px;
    height: 70px;
}

.logo:hover{
    cursor: pointer;
}

.sesion {
    width: 416px;
    height: 429px;
    top: 296px;
    left: 787px;
    gap: 25px;
    margin-left: 25vw;
    margin-top: 15vh;
    z-index: 2;
}

.inter {
    display: flex;
    flex-direction: column;
}

.regName {
    color: white;
    font-size: 42px;
    font-weight: 400;
    line-height: 50.4px;
    text-align: left;
    margin-bottom: 10px;
}

.conText {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.linkText {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    margin-right: 4px;
}

.linked {
    color: #E4FD3F;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
}

.linked:hover {
    color: #FFFFFF;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.inp {
    font-size: 16px;
    width: 416px;
    height: 36px;
    gap: 0px;
    border-radius: 8px;
    border: 1px solid #A4A4A4;
    background-color: #262626;
    color: #FFFFFF;
}

.inp:hover {
    background-color: #444444;
}

.etiqueta {
    width: 416px;
    height: 18px;
    gap: 10px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.contInp{
    position: relative;
}

.iconoContainer {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.iconoContainer:hover{
    cursor: pointer;
}

.aviso {
    font-size: 10px;
    color: rgb(255, 255, 255);
}

.avisoB{
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
}

.btn {
    width: 416px;
    height: 36px;
    gap: 0px;
    border-radius: 8px;
    background-color: #E4FD3F;
    color: black;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    margin-bottom: 15px;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.icon{
    width: 10vw;
    height: 3vh;
}

.contCheck{
    display: flex;
    flex-direction: row;
}

.linkTextCheck {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    margin-right: 4px;
}

.linkedCheck {
    color: #E4FD3F;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
}

.linkedCheck:hover {
    color: #FFFFFF;
}

.checkbox {
    margin-right: 8px;
    color: #333;
    background-color: #333;
  }
  
  .labelCheckbox {
    font-size: 14px;
    color: #333;
  }  

.copyright {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-top: auto;
    margin-left: 25vw;
}

.confirm{
    width: 416px;
    height: 429px;
    top: 296px;
    left: 787px;
    gap: 25px;
    margin-left: 25vw;
    margin-top: 25vh;
    z-index: 2;
}

.filled{
    width: 54px;
    height: 54px;
    margin-bottom: 25px;
}
.linkTextConfirm {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    margin-bottom: 25px;
}
.textConfirm{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: center;
}
.btnConfirm {
    width: 416px;
    height: 46px;
    gap: 0px;
    border-radius: 8px;
    background-color: #E4FD3F;
    color: black;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    margin-bottom: 25px;
}

.btnConfirm:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .container {
        background-image: none;
        height: auto;
    }
    .register{
        background-size: 100vw 100vh;
        background-position: center;
    }
    .sesion {
        width: 100vw;
        height: auto;
        margin-top: 30vw;
        margin-left: 0px;
    }
    .contLogo {
        display: flex;
        justify-content: center;
        width: 100vw;
        height: auto;
    }
    .logo {
        width: 30vw;
        height: 15vw;
        left: 40vw;
        top: 3vw;
    }
    .inter{
        width: 80vw;
        margin-left: 10vw;
        margin-right: 10vw;
        align-items: flex-start;
    }
    .regName {
        width: 60vw;
        color: #FFFFFF;
        font-size: 8vw;
        line-height: 12vw;
        text-align: left;
        margin-bottom: 3vw;
    }
    .conText {
        width: 80vw;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 3vw;
    }
    .linkText {
        font-size: 3vw;
        line-height: 5vw;
        text-align: left;
        margin-right: 1vw;
    }
    .linked {
        font-size: 3vw;
        line-height: 5vw;
        text-align: left;
    }
    .inputs {
        width: 80vw;
        margin-bottom: 3vw;
    }
    .etiqueta {
        width: 80vw;
        height: auto;
        font-size: 4vw;
        line-height: 4vw;
        text-align: left;
        margin-bottom: 2vw;
    }
    .inp {
        width: 80vw;
        height: 10vw;
        margin-bottom: 0px;
        font-size: 4vw;
    }
    .btn {
        width: 82vw;
        height: 10vw;
        font-size: 4vw;
        margin-bottom: 5vw;
    }
    .recuperacion{
        font-size: 4vw;
        line-height: 4vw;
    }
    .linkTextCheck{
        font-size: 3vw;
    }
    .linkedCheck{
        font-size: 3vw;
    }
    .copyright{
        font-size: 3vw;
        margin-left: 0px;
        line-height: 3vw;
        text-align: center;
        margin-bottom: 2vw;
    }
    .aviso{
        margin-bottom: 1vw;
    }
    .confirm{
        width: 100vw;
        height: auto;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 35vw;
    }
    .btnConfirm{
        width: 82vw;
        height: 10vw;
        font-size: 4vw;
        margin-bottom: 5vw;
    }
}