.conteiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position:fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    background-color: #000000c2;
}

.contMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 250px;
    margin-left: 20px;
    margin-bottom: 200px;
    background-color: #353535;
    border-radius: 12px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.42px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.control{
    font-family: Oswald;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.42px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px; 
}

.aviso{
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.botones{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.btnAgregar{
    width: 40%;
    height: 6vh;
    background-color: #E4FD3F;
    color: #000000;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
}

.btnAgregar:hover{
    background-color: #000000;
    color: #E4FD3F;
}

.btnCancelar{
    width: 40%;
    height: 6vh;
    background-color: #989897;
    color: #e70c0c;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    margin-right: 20px;
    cursor: pointer;
}

.btnCancelar:hover{
    background-color: #e70c0c;
    color: #989897;
}


@media screen and (max-width: 767px) {
    .conteiner {
        flex-direction: column;
        justify-content: flex-start;
        width: 98vw;
        height: 90vh;
    }
    
    .contMenu {
        width: 98vw;
        margin-left: 0px;
        margin-top: 10vh;
    }
}