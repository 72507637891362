.conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 1800px;
    height: 1200px;
    top: 0px;
    background-color: #ffffff4e;
    z-index: 9;
}

.modalContent {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 80px;
    right: 300px;
    width: 900px;
    height: 500px;
    background-image: url("../../assets/FondoMain.png");
    color: #FFFFFF;
    background-size: 100% 100%;
    padding: 10px;
    z-index: 10;
    border-radius: 12px;
}

.contTitle {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.seccion {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
}

.metricas {
    display: flex;
    flex-direction: row;
    width: 900px;
    height: 400px;
}

.contRadar {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 400px;
    align-items: center;
}

.contRadarDes {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 400px;
    align-items: center;
}

.etiqueta {
    display: flex;
    flex-direction: row;
    width: 450px;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.radar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/GraficoBase.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 225px 260px;
    width: 350px;
    height: 300px;
    padding-left: 30px;
    padding-bottom: 0px;
}

.icon {
    width: 20px;
    height: 20px;
}

.etiquetaTxt {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 10px;
}

.btnCloset {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
}

.btnCloset:hover {
    cursor: pointer;
    color: #E4FD3F;
}

.campo {
    width: 500px;
    height: 150px;
    margin-bottom: 15px;
}

.sub {
    font-family: Oswald;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.contPosicion {
    display: flex;
    width: 160px;
    height: 30px;
    border-radius: 100px;
    background-color: #262626;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-left: 5px;
}

.posiciones {
    display: flex;
    flex-direction: row;
}

.posicion {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
}

.botonera {
    display: none;
}

.btnAgregar {
    display: none;
}

.userCard {
    display: none;
}

@media screen and (max-width: 767px) {
    .conteiner {
        width: 100vw;
        height: 100vh;
        margin-top: 16vh;
    }

    .modalContent {
        top: 9vh;
        right: 0px;
        width: 100vw;
        height: 100vh;
        padding: 1vw;
        border-radius: 0px;
    }

    .contTitle {
        margin-top: 2vw;
        margin-bottom: 2vw;
        display: flex;
        width: 90vw;
        justify-content: left;
        align-items: flex-start;
    }

    .seccion {
        font-size: 7vw;
        text-align: left;
    }

    .metricas {
        display: flex;
        flex-direction: column;
        width: 98vw;
        height: 70vh;
        align-items: center;
    }

    .contRadar {
        width: 96vw;
        height: auto;
    }

    .contRadarDes {
        display: none;
    }

    .etiqueta {
        display: none;
    }

    .radar {
        background-size: 225px 260px;
        width: 350px;
        height: 300px;
        padding-left: 30px;
        padding-bottom: 0px;
    }

    .btnCloset {
        display: none;
    }

    .btnCloset:hover {
        cursor: pointer;
        color: #E4FD3F;
    }

    .campo {
        width: 110vw;
        height: 20vh;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .sub {
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .contPosicion {
        width: 40vw;
        height: 8vw;
        margin-bottom: 3vw;
        margin-left: 2vw;
        margin-left: 2vw;
    }

    .posicion {
        font-size: 4vw;
    }

    .botonera {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90vw;
        height: 8vh;
        background-color: #262626;
        border-radius: 10px;
        margin-bottom: 3vw;
    }

    .btnMenu {
        width: 45vw;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2vw;
        margin-left: 2vw;

    }

    .btnMenuSelect {
        width: 45vw;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        margin-right: 2vw;
        margin-left: 2vw;
        border-radius: 10px;

        .txtMenu {
            color: #1C1D1E
        }
    }

    .txtMenu {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }

    .btnAgregar {
        display: block;
        width: 94vw;
        height: 6vh;
        background-color: #E4FD3F;
        color: #000000;
        border-radius: 10px;
        font-size: 4vw;
        font-weight: 600;
        position: fixed;
        bottom: 10vh;
        left: 3vw;
    }

    .userCard {
        display: flex;
        flex-direction: row;
        width: 90vw;
        height: 8vh;
        background-color: #262626;
        border-radius: 10px;
        align-items: center;
    }

    .foto {
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        border: 2px solid #E4FD3F;
        margin-left: 2vw;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 3vw;
        width: 50vw;
    }

    .name {
        font-family: Oswald;
        font-size: 4vw;
        font-weight: 500;
        line-height: 29.64px;
        text-align: left;
        color: #FFFFFF;
        margin-left: 0px;
        margin-right: 0px;
    }

    .position {
        font-family: Oswald;
        font-size: 3vw;
        font-weight: 500;
        line-height: 29.64px;
        text-align: left;
        color: #FFFFFF;
        margin-left: 0px;
        margin-right: 0px;
    }
}