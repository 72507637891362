.conteiner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}
.gif{
    width: 10vw;
    height: 10vw;
}