.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.totales {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    height: 40vh;
    border-radius: 10px;
    background-color: rgb(236, 236, 236);
    margin-bottom: 2vh;
}

.dataTotales {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.graficasTotales {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50vw;
    height: 40vh;
}

.grafica {
    width: 30vw;
    height: 30vh;
}

.Uregistrados {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}

.title {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: white;
    font-family: Oswald;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.64px;
    text-align: center;
    margin-bottom: 10px;
}

.subTitle {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-left: 2px;
    margin-right: 4px;
}

.num {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-left: 4px;
    margin-right: 2px;
}