:root {
    --color-primary: #E4FD3F;
    --color-white: #FFFFFF;
    --color-dark: #262626;
    --font-oswald: 'Oswald';
    --font-raleway: 'Raleway';
    --border-radius: 8px;
}

.conteiner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fondoEscudo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-image: url("../../assets/Escudo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    margin-bottom: 30px;
}

.contFoto {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    border-radius: 10px;
    background-image: url("../../assets/backEscudo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    margin-top: 20px;
    z-index: 2;
}

.foto {
    width: 100px;
    height: 100px;
    border: 2px solid var(--color-primary);
    border-radius: 100px;
    margin-top: 20px;
}

.name {
    color: #FFFFFF;
    font-size: 16px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-top: 12px;
}

.direccion {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: center;
    color: #FFFFFF;
}

.seccionName {
    font-family: Oswald;
font-size: 25px;
font-weight: 500;
line-height: 18px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: white;
margin-top: 10px;
text-transform: uppercase;
}

.contMetriBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    margin-bottom: 0px;
}

.contMetriBtnV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 30px;
    margin-bottom: 0px;
}

.txtBtn {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: var(--color-primary);
    align-self: end;
}

.txtBtn:hover {
    color: #FFFFFF;
    cursor: pointer;
}

.equipo, .equipoV {
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 100%;
}

.equipoV {
    width: 90%;
}

.semana, .semanaV {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin: 20px 0 0 0;
    width: 100%;
}

.semanaV {
    width: 90%;
}

.diaSemana {
    padding: 3px;
    color: #dcd0d0;
    font-size: 14px;
    border-radius: 4px;
    width: 30px;
}

.contMetricaE, .contMetricaEV {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
}

.contMetricaE {
    width: 95%;
}

.contMetricaEV {
    width: 90%;
}

.metricaNameE {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.23px;
    text-align: left;
    color: #FFFFFF;
}

.verificadas{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}


.numE {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.contPartidos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

}

.contPartidosV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 20px;
    margin-top: 10px;
}

.txtP {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.78px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 20px;
}

.contMetricas {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
}


.contMetricas:hover{
    cursor: pointer;
    .metrica{
        background-color: #2c2c2b;
    }
} 

.notIcon{
   width: 30px;
   height: 30px; 
}

.balon{
    width: 22px;
    height: 22px;
    position: relative;
    left: 13px;
    top: 13px;
    
  }

.partidos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icon {
    width: 30px;
    height: 30px;
}
.icon1 {
    width: 20px;
    height: 20px;
}



.metrica {
    width: 94%;
    height: 60px;
    background-color: var(--color-dark);
    border-radius: var(--border-radius);
    justify-content: space-between;
    padding: 10px;
    display: flex;

    flex-direction: row;
}

.contNum {
    width: 60px;
    height: 50px;
}

.contNumB {
    width: 60px;
    height: 50px;
    display: flex;
    flex-direction: column;
}

.metricaName {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
    color: #FFFFFF;
}

.num {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 5px
}

.contUnidad {
    display: flex;
    flex-direction: row;
}

.contUnidadB {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.unidad {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    align-content: end;
}

.grafica {
    width: 57px;
    height: 47px;
}

.contUserInfo {
    display: flex;
    flex-direction: row;
    width: 60px;
    margin-top: 20px;
    margin-right: 120px;
}

.userInfo {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    border-right: 2px solid white;
    padding-right: 40px;
}

.infoB {
    display: flex;
    flex-direction: column;
}

.dataName {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: center;
    color: #FFFFFF;
}

.dataInfo {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
    text-align: center;
    color: #FFFFFF;
}

.contBSD {
    width: 70px;
    height: 60px;
    background-image: url("../../assets/BSDfondo.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    margin-top: 20px;
}

.bsdT {
    font-family: Oswald;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.27px;
    text-align: center;
    color: #1C1D1E;
}

.score {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 400;
    line-height: 41.5px;
    text-align: center;
    color: #1C1D1E;
}

.tu{
    width: 15px;
    height: 15px;
}

.condicion{
    width: 15px;
    height: 15px;
    margin-top: 5px; 
    margin-right: 5px;
}

.sensura{
    width: 260px;
    height: 400px;
    border-radius: 12px;
}
.metricaE {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metricaNameE {
    margin-bottom: 8px;
}

.contCirculo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(228, 253, 63, 1); /* Verde claro */
    display: flex;
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

.numE {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
    position: absolute;
}
.daysText {
    font-size: 15px; /* Tamaño del texto */
    color: #ffffff; /* Color blanco */
    padding: 5px 10px; /* Espaciado interno para crear un efecto de botón */
    border-radius: 8px; /* Bordes redondeados */
    font-weight: bold; /* Negrita */
    text-transform: capitalize; /* Convierte el texto a mayúsculas */
  
}


@media screen and (max-width: 767px) {
    .conteiner{
        width: 94vw;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        align-items: flex-start;
    }
    .fondoEscudo{
        width: 94vw;
        margin-bottom: 8vw;
    }
    .contFoto{
        width: 94vw;
        height: auto;
        margin-top: 0px;
    }
    .foto{
        width: 30vw;
        height: 30vw;
    }
    .name{
        font-size: 5vw;
        margin-top: 3vw;
    }
    .direccion{
        font-size: 4vw;
        margin-top: 1vw;
    }
    .contUserInfo{
        width: 18vw;
        margin-top: 6vw;
        margin-right: 25vw;
    }
    .userInfo{
        margin-left: 5vw;
    }
    .info{
        padding-right: 9vw;
    }
    .dataName{
        font-size: 3vw;
    }
    .dataInfo{
        font-size: 4vw;
    }
    .contBSD{
        width: 15vw;
        height: 16vw;
        margin-top: 6vw;
    }
    .bsdT{
        font-size: 3vw;
    }
    .score{
        font-size: 7vw;
    }
    .equipo{
        width: 94vw;
    }
    .contMetriBtn{
        width: 94vw;
        height: 8vw;
        margin-bottom: 2vw;
    }
    .seccionName{
        font-size: 5vw;
        margin-bottom: 3vw;
    }
    .txtBtn{
        font-size: 4vw;
        margin-right: 2vw;
    }
    .contMetricaE{
        width: 90vw;
        margin-bottom: 3vw;
        margin-right: 4vw;
    }
    .metricaNameE{
        font-size: 4vw;
    }
    .numE{
        font-size: 4vw;
    }
    .contPartidos{
        width: 92vw;
        margin-bottom: 5vw;
    }
    .txtP{
        font-size: 4vw;
        margin-right: 5vw;
    }
    .partidos{
        width: 40vw;
    }
    .icon{
        width: 5vw;
        height: 5vw;
    }
    .contMetricas{
        margin-bottom: 5vw;
    }
    .metrica{
        width: 85vw;
        height: 18vw;
        align-items: center;
        padding: 2vw;
    }
    .contNum{
        width: 15vw;
        height: 13vw;
    }
    .metricaName{
        font-size: 4vw;
    }
    .num{
        font-size: 7vw;
        margin-right: 1vw;
    }
    .unidad{
        font-size: 4vw;
    }
    .grafica{
        width: 17vw;
        height: 15vw;
    }
    .verificadas{
        margin-top: 5vw;
    }
    .semana {
        width: 100%;
        margin-left: 5px;
        margin-bottom: 10px;
      }
      .diaSemana {
        width: 27px;
      }
      .equipoV {
        width: 90%;
    }
    .semanaV{
        width: 100%;
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .partidos{
        width: auto;
    }
}

/* Tablets y pantallas medianas */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contFoto {
        width: 400px;
    }

    .foto {
        width: 120px;
        height: 120px;
    }

    .contUserInfo {
        margin-right: 80px;
    }

    .metrica {
        width: 90%;
    }
}

/* Pantallas más pequeñas pero no móviles */
@media screen and (min-width: 481px) and (max-width: 767px) {
    .contFoto {
        width: 80vw;
    }

    .foto {
        width: 25vw;
        height: 25vw;
    }

    .name {
        font-size: 4.5vw;
    }

    .metrica {
        width: 90%;
        height: 15vw;
    }
}

