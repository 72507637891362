.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    margin-top: 20px;
}

.listas {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
}

.listaUsuarios {
    width: 550px;
    margin: 10px;
}

.lista{
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 430px;
    overflow: auto;
}

.user {
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 85px;
    justify-content: space-between;
    align-items: center;
    background-color: #262626;
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.foto {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-left: 10px;
    border: 1px solid #E4FD3F;
}

.info {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 70px;
    align-items: flex-start;
}

.title {
    color: white;
    font-family: Oswald;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.64px;
    text-align: center;
    margin-bottom: 10px;
}

.subTitle {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: center;
    color: #FFFFFF;
}

.name {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
}

.mail {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
}

.id {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.btnActivar {
    background-color: green;
    color: white;
    width: 80px;
    font-size: 14px;
    height: 30px;
    border-radius: 8px;
}

.btnActivar:hover {
    cursor: pointer;
    box-shadow: inset -6px -6px 10px rgba(255, 255, 255, .7), inset 6px 6 10px rgba(0, 0, 0, .25);
    color: rgb(0, 0, 0);
}

.btnDesactivar {
    background-color: rgb(0, 0, 0);
    color: white;
    width: 80px;
    font-size: 14px;
    height: 30px;
    border-radius: 8px;
}

.btnDesactivar:hover {
    background-color: #E4FD3F;
    color: rgb(0, 0, 0);
}

.btnVer {
    background-color: #E4FD3F;
    color: rgb(0, 0, 0);
    width: 80px;
    font-size: 14px;
    height: 30px;
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 10px;
}

.btnVer:hover {
    background-color: black;
    color: #FFFFFF;
}

.contName {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.inline {
    color: rgb(0, 255, 0);
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
}

.offline {
    color: rgb(156, 156, 156);
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
}