.container {
    width: 746px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: center;
    margin-top: 110px;
    border-radius: 10px;
}

.title {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 400;
    line-height: 50.4px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 150px;
    margin-bottom: 25px;
}

.menuOtro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    margin-bottom: 25px; 
}

.btnDiv {
    display: flex;
}

.btnDivDesc {
    display: none;
}

.btnTtx {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
}

.btnTtxAct {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #E4FD3F;
    cursor: pointer;
}

.contFoto {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nacimiento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 30px;
}

.descripcion {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.contSelect {
    display: flex;
    flex-direction: row;
}

.selector {
    width: 190px;
    height: 30px;
    border-radius: 8px;
    justify-content: space-between;
    background-color: #262626;
    color: #A4A4A4;
}

.selector:hover {
    background-color: #444444;
    cursor: pointer;
}

.selectorM {
    width: 340px;
    height: 30px;
    border-radius: 8px;
    justify-content: space-between;
    background-color: #262626;
    color: #A4A4A4;
    margin-left: 10px;
    margin-right: 10px;
}

.selectorM:hover {
    background-color: #444444;
    cursor: pointer;
}

.contPie {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contOpcionesPie {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxP {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #262626;
    background-color: #262626;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}

.checkboxP:checked {
    background-color: #E4FD3F;
    border-color: #262626;
}

.labelP {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 50px;
}

.cont {
    display: flex;
    flex-direction: row;
    width: 746px;
    height: 74px;
}

.camp {
    display: flex;
    flex-direction: column;
    width: 373px;
    height: 74px;
}

.descripcionCamp {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.contInp {
    display: flex;
    flex-direction: row;
}

.selectCamp {
    width: 360px;
    height: 36px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
}

.inputCamp {
    width: 360px;
    height: 30px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
}

.form {
    text-align: start;
}

.part {
    display: flex;
    flex-direction: column;
}

.subPart {
    display: flex;
    flex-direction: row;
    width: 746px;
}

.contUpLoad {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.Pnom {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.foto {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #E4FD3F;
    cursor: pointer;
}

.inputext {
    width: 250px;
    height: auto; /* Ajusta esta línea si es necesario */
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 41.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 0px;
    margin-left: 20px;
    cursor: pointer;
}

.contInput {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inp {
    width: 327px;
    height: 36px;
    border-radius: 8px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.inp::placeholder {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #7c7979;
}

.inp:hover {
    background-color: rgba(0, 0, 0, 0.249);
}

.unidad {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 7px;
}

.btn {
    width: 223px;
    height: 36px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 10px;
    cursor: pointer;
    float: right;
    margin-right: 5px;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.danger {
    color: rgb(255, 196, 0);
    font-size: 10px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.nota {
    font-size: 14px;
    color: white;
}

.historial {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.experiencia {
    width: 746px;
    height: 120px;
    border-radius: 8px;
    background-color: #262626;
    border: 1px solid #A4A4A4;
    color: #FFFFFF;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
}

.exptime {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.descarga {
    display: flex;
    flex-direction: column;
}

.cv {
    display: flex;
    flex-direction: column;
    margin-top: 10px
}

.subirPdf {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/subirPdf.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 250px;
    height: 75px;
    border-radius: 8px;
    cursor: pointer;
}

.pdf {
    width: 77px;
    height: 75px;
}

.btnVer {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 10px;
    cursor: pointer;
}

.btnVer:hover {
    color: #E4FD3F;
}

.seccion {
    display: flex;
    flex-direction: row;
    width: 746px;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.descripcionB {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 15px;
    margin-top: 20px;
}

.sub {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
}

.check {
    width: 36px;
    height: 20px;
    cursor: pointer;
}

.btnC {
    width: 180px;
    height: 36px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btnC:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
}

.btnD {
    width: 180px;
    height: 36px;
    background-color: #FF0000;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btnD:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
}

.contCuenta{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    width: 746px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .container {
        width: 100vw;
        margin-top: 12vh;
    }
    
    .title {
        font-size: 9vw;
        margin-bottom: 7vw;
        margin-left: 3vw;
    }

    .contFoto{

    }
    
    .menu {
        width: 40vw;
        margin-bottom: 7vw;
        margin-left: 3vw;
    }
    
    .menuOtro{
        width: 60vw;
        margin-bottom: 7vw;
        margin-left: 7vw;
    }

    .btnTtx {
        font-size: 4vw;
    }
    
    .btnTtxAct {
        font-size: 4vw;
    }
    
    .nacimiento {
        margin-bottom: 2vw;
        margin-top: 7vw;
        width: 90vw;
    }
    
    .descripcion {
        font-size: 4vw;
        margin-bottom: 2vw;
    }
    
    .contSelect {
        flex-direction: column;
    }
    
    .selector {
        width: 90vw;
        height: 5vh;
    }
    
    
    .selectorM {
        width: 90vw;
        height: 5vh;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
    
    .selectorM:hover {
        background-color: #444444;
        cursor: pointer;
    }
    
    .contPie {
        width: 90vw;
        margin-top: 2vw;
        margin-bottom: 5vw;
    }
    
    .contOpcionesPie {
        width: 90vw;
    }
    
    .checkboxP {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }
    
    .labelP {
        font-size: 4vw;
        margin-right: 5vw;
    }
    
    .cont {
        width: 90vw;
        flex-direction: column;
        height: 9vh;
        margin-bottom: 9vh;
    }

    .conto {
        width: 90vw;
        flex-direction: column;
        height: 9vh;
        margin-bottom: 0px;
        margin-top: 4vh;
    }
    
    .camp {
        width: 90vw;
        height: 13vh;
    }
    
    .descripcionCamp {
        font-size: 4vw;
        margin-bottom: 1vw;
    }
    
    .selectCamp {
        width: 90vw;
        height: 5vh;
        margin-bottom: 3vw;
    }
    
    .form {
        text-align: start;
        width: 90vw;
        margin-left: 5vw;
    }
    
    .part {
        display: flex;
        flex-direction: column;
        width: 90vw;
    }
    
    .subPart {
        display: flex;
        flex-direction: column;
        width: 90vw;
        margin-top: 3vw;
    }
    
    .foto {
        width: 18vw;
        height: 18vw;
    }
    
    .inputext  {
    width: 280px;
    height: 46px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #1C1D1E;
    margin-top: 0px;
    cursor: pointer;}

    
    .inp {
        width: 80vw;
        height: 5vh;
        font-size: 4vw;
    }
    
    .inp::placeholder {
        font-size: 4vw;
    }
    
    
    .unidad {
        font-size: 4vw;
        margin-left: 2vw;
    }
    
    .btn {
        width: 90vw;
        height: 6vh;
        font-size: 4vw;
        margin-top: 5vw;
        margin-right: 0px;
        margin-bottom: 5vw;
    }
    
    
    .danger {
        font-size: 3vw;
    }
    
    .nota {
        font-size: 3vw;
        color: white;
    }
    
    .historial {
        margin-top: 4vw;
    }
    
    .experiencia {
        width: 90vw;
        height: 10vh;
        font-size: 4vw;
    }
    
    .exptime {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }
    
    .descarga {
        display: flex;
        flex-direction: column;
    }
    
    .cv {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 5vw;
    }
    
    .subirPdf {
        display: flex;
        flex-direction: column;
        background-image: url("../../assets/subirPdf.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 250px;
        height: 75px;
        border-radius: 8px;
        cursor: pointer;
    }
    
    .pdf {
        width: 77px;
        height: 75px;
    }
    
    .btnVer {
        font-family: Raleway;
        font-size: 13px;
        font-weight: 600;
        line-height: 17.61px;
        text-align: left;
        color: #FFFFFF;
        margin-left: 10px;
        cursor: pointer;
    }
    
    .btnVer:hover {
        color: #E4FD3F;
    }
    
    .seccion {
        width: 90vw;
        margin-bottom: 4vw;
    }

    .contCuenta{
        width: 90vw;
        margin-left: 5vw;
    }
    
    .descripcionB {
        font-size: 5vw;
        margin-bottom: 4vw;
        margin-top: 1vw;
    }
    
    .sub {
        font-size: 3vw;
    }
    
    .check {
        width: 9vw;
        height: 5vw;
    }
    
    .btnC {
        width: 40vw;
        height: 5vh;
        font-size: 4vw;
    }
    
    .btnC:hover {
        background-color: rgba(0, 0, 0, 0.214);
        color: white;
        border-color: white;
    }
    
    .btnD {
        width: 40vw;
        height: 5vh;
        font-size: 4vw;
    }
    
    .btnD:hover {
        background-color: rgba(0, 0, 0, 0.214);
        color: white;
        border-color: white;
    }
}
.uploadButton{
    width: 280px;
    height: 46px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 120px;
    cursor: pointer;}