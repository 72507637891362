.container {
    position: fixed;
    top: 88px;
    right: 50px;
    width: 150px;
    height: 80px;
    background-color: #262626;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    color: white;
    border-radius: 10px;
    /* justify-content: center; */
    align-items: flex-end;
}

.subT {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF;
    margin: 15px;
    margin-bottom: 0px;
}

.subT:hover {
    cursor: pointer;
    color: #a4a3a3;
}
