.conteiner{
  display: flex;
  width: 100vw;
  position:absolute;
  justify-content: center;
}

.nav {
  display: flex;
  width: 94vw;
  height: 96px;
  align-items: center;
  justify-content: space-between;
}

.navLinks {
  width: 593px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding-top: 15px;
  z-index: 9999;
}

.navLinksU {
  width: 323px;
  justify-content: right;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding-top: 15px;
  padding-right: 29px;
  z-index: 9999;
}

.navLinksL {
  width: 203px;
  justify-content: left;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding-top: 15px;
  padding-left: 27px;
  z-index: 9999;
}

.btn {
  display: flex;
  width: 155px;
  height: 76px;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.a {
  font-family: Oswald;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.btn:hover {
  cursor: pointer;
  border-bottom: 3px solid #E4FD3F;
  .a{
    color: #E4FD3F;
  }
}

.logo {
  width: 150px;
  height: 40px;
}

.logo:hover{
  cursor: pointer;
}

.iconNoti{
  width: 24px;
  height: 24px;
  margin-right: 57px;
  margin-top: 15px;
}

.iconNoti:hover{
  cursor: pointer;
}

.user {
  width: 350px;
  height: 58px;
  display: flex;
  flex-direction: row;
}

.user:hover{
  cursor: pointer;
}

.contInfo {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.name {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.13px;
  text-align: right;
  color: #FFFFFF;
  padding-top: 10px;
}

.info {
  font-family: Oswald;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.78px;
  text-align: right;
  color: #FFFFFF;
}

.iconUser {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border: 2px solid #E4FD3F;
  margin-left: 20px;
}

.navAnd{
  display: none;
}

@media screen and (max-width: 767px) {

  .nav{
    display: none;
  }
  
  .navAnd{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position:fixed;
    width: 100vw;
    height: 19vw;
    background-image: url("../../assets/fondoLimpio.png");
    background-size: 100%;
    z-index: 1000;
    top: 0px;
  }
  .logoAnd{
    width: 30vw;
    height: 10vw;
    margin-left: 5vw;
    margin-top: 4vw; 
  }
  .btnAnd{
    width: 25vw;
    height: 10vw;
    margin-right: 3vw;
    margin-top: 4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .iconNotiAnd{
    width: 8vw;
    height: 8vw;
  }
  .menuAnd{
    width: 10vw;
    height: 10vw;
  }
}