.container {
    position: fixed;
    overflow-y: auto;
    scrollbar-width: none;
    top: 10vh;
    right: 2vw;
    width: 45vw;
    height: 35vh;
    background-color: #262626;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    display: block;
    z-index: 1000;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.contMenu {
    display: flex;
    flex-direction: column;
    margin-top: 4vw;
}

.menu {
    height: 7vh;
    margin-left: 4vw;
}

.a {
    font-family: Raleway;
    font-size: 5vw;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}