.conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 1800px;
    height: 1200px;
    top: 0px;
    background-color: #ffffff4e;
    z-index: 9;
}

.conteinerDesk {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modalLista {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 100px;
    right: 350px;
    width: 900px;
    height: 550px;
    background-image: url("../../assets/fondoLimpio.png");
    background-repeat: no-repeat;
    background-size: 900px 555px;
    background-position: 0px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    z-index: 1000;
    color: white;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.modalListaDesk {
    display: flex;
    flex-direction: row;
    background-image: url("../../assets/fondoLimpio.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.cont {
    display: flex;
    flex-direction: row;
    width: 900px;
    height: 550px;
    margin-top: 30px;
    margin-left: 20px;
}

.contDesk {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    margin-left: 20px;
}

.contGraficos {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 430px;
    height: 450px;
    margin-right: 10px;
}

.contGraficosDesk {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 95%;
    height: 450px;
}

.contCancha {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 430px;
    height: 450px;
    margin-right: 10px;
}

.contCanchaDesk{
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 95%;
    height: 450px;
}

.contJugadores {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 410px;
    height: 500px;
    margin-left: 15px;

}

.contTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contTitleDesk {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 95%;
}

.contTitleB {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contTitleBDesk {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 95%;
}

.contSubTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contSubTitleDes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.contDesepeno {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.contDesepenoDes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
}

.contBSD {
    width: 50px;
    height: 40px;
    display: flex;
    flex-direction: column;
    background-color: #E4FD3F;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    align-items: center;
    justify-content: center;
}

.contDescripcion {
    display: flex;
    margin-bottom: 12px;
}

.descripcion {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
}

.bsdTxt {
    font-family: Oswald;
    font-size: 8.02px;
    font-weight: 400;
    line-height: 11.88px;
    text-align: center;
    color: #1C1D1E;
}

.bsd {
    font-family: Oswald;
    font-size: 17.27px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
    color: #1C1D1E;
}

.subTitle {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.btnTxt {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #E4FD3F;
    cursor: pointer;
}

.btnTxt:hover {
    color: #FFFFFF;
}

.desempeno {
    display: flex;
    flex-direction: row;
}

.desName {
    font-family: Raleway;
    font-size: 11px;
    font-weight: 400;
    line-height: 12.91px;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    width: 14px;
    height: 14px;
    margin-right: 7px;
}

.iconA {
    width: 14px;
    height: 14px;
    margin-right: 7px;
    filter: invert(53%) sepia(54%) saturate(1460%) hue-rotate(97deg) brightness(92%);

}

.contGrafica {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 430px;
    height: 600px;
}

.contGraficaDes {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 430px;
    height: 600px;
}

.contGraficaDesDesk {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.cancha {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 430px;
    height: 500px;
}

.canchaDes {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 430px;
    height: 500px;
}

.canchaImg {
    width: 100%;
    height: 100%;
}

.areaPorteros {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 40px;
    right: 160px;

}

.areaDefensaCentrales {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 85px;
    right: 160px;
}

.areaDefensaLateralIzquierdos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 95px;
    right: 250px;
}

.areaDefensaLateralDerechos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 95px;
    right: 70px;
}

.areaMediocentros {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 165px;
    right: 160px;
}

.areaInteriorIzquierdos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 165px;
    right: 55px;
}

.areaInteriorDerechos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 165px;
    right: 265px;
}

.areaDelanteroCentrales {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 245px;
    right: 155px;
}

.areaDelanteroLateralDerechos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 235px;
    right: 75px;
}

.areaDelanteroLateralIzquierdos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 235px;
    right: 225px;
}

.areaMedioCentroDefensivo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 205px;
    right: 155px;
}

.areaMedioCentroOfensivo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 40px;
    position: absolute;
    z-index: 2;
    bottom: 125px;
    right: 155px;
}

.posicionCancha {
    position: absolute;
    z-index: 3;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px;
    border-style: solid;
}

.lineOne {
    height: 230px;
    position: absolute;
    z-index: 1;
    bottom: 40px;
    right: 190px;
}

.lineTwo {
    width: 340px;
    position: absolute;
    z-index: 1;
    bottom: 155px;
    right: 20px;
}

.lineOneDesk {
    height: 230px;
    position: absolute;
    z-index: 1;
    bottom: 35px;
    right: 170px;
}

.lineTwoDesk {
    width: 340px;
    position: absolute;
    z-index: 1;
    bottom: 145px;
    right: 0px;
}

.btn {
    width: 150px;
    height: 40px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btn:hover {
    background-color: #ffffff;
    color: #1C1D1E;
}

.contListaJugadores {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: top;
    overflow-y: auto;
    scrollbar-width: none;
    width: 100%;
    height: 350px;
    margin-top: 40px;
}

.contJugador {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 60px;
    justify-content: left;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.contJugadorTwo {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 60px;
    justify-content: left;
    align-items: center;
    background-color: #353535;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.num {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 30px;
    margin-left: 10px;
}

.foto {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px;
    border-style: solid;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
}

.name {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.posicion {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.bsdJugador {
    width: 45px;
    height: 40px;
    display: flex;
    flex-direction: column;
    background-color: #E4FD3F;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: center;
    justify-content: center;
}

.bsdTxtJugador {
    font-family: Oswald;
    font-size: 8.02px;
    font-weight: 400;
    line-height: 11.88px;
    text-align: center;
    color: #1C1D1E;
}

.bsdByJugador {
    font-family: Oswald;
    font-size: 17.27px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
    color: #1C1D1E;
}

.cerrar {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 20px;
    cursor: pointer;
}

.cerrar:hover {
    color: #E4FD3F;
}

.btnAdd {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    background-color: #1C1D1E;
    text-align: center;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 5px;
    font-family: Oswald;
    font-size: 20px;
    padding-bottom: 2px;
}

.iconB {
    width: 20px;
    height: 13px;
    /* margin-bottom: 5px; */
}

.btnAdd:hover {
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;

    .iconB {
        filter: invert(1) sepia(100%) saturate(10000%);
    }
}

.botonera {
    display: none;
}

.btnAgregar{
    display: none;
}

.back{
    display: none;
}

.btnTtxt{
    display: none;
}

@media screen and (max-width: 767px) {
    .conteiner {
        width: 100vw;
        height: 100vh;
        top: 0px;
        left: 0px;
        background-color: #ffffff4e;
        z-index: 9;
    }

    .modalLista {
        flex-direction: column;
        top: 9vh;
        right: 0px;
        width: 100vw;
        height: 100vh;
        background-size: 100% 100%;
        border-radius: 0px;
        justify-content: flex-start;
    }

    .btnTtxt{
        display: block;
        font-size: 4vw;
        margin-top: 3vh;
        margin-right: auto;
        margin-left: 2vw;
    }

    .cont {
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        margin-top: 0px;
        margin-left: 0px;
    }

    .contGraficos {
        justify-content: center;
        width: 98vw;
        height: auto;
        margin-right: 0px;
        margin-top: 8vw;
    }

    .contCancha {
        width: 98vw;
        height: auto;
        margin-right: 0px;
        margin-top: 8vw;
    }

    .contJugadores {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 98vw;
        height: 45vh;
        margin-left: 0px;

    }

    .contTitle {
        margin-bottom: 3vw;
        margin-right: auto;
        width: 98vw;
        justify-content: space-between;
    }

    .contTitleB {
        display: none;
    }

    .contSubTitle {
        margin-bottom: 3vw;
        margin-top: 7vw;
    }

    .contDesepeno {
        margin-bottom: 8vw;
        margin-top: 4vw;
    }

    .title {
        font-size: 8vw;
    }

    .contBSD {
        width: 14vw;
        height: 12vw;
    }

    .contDescripcion {
        margin-bottom: 3vw;
        margin-right: auto;
        width: 96vw;
    }

    .descripcion {
        font-size: 4vw;
    }

    .bsdTxt {
        font-size: 2vw;
    }

    .bsd {
        font-size: 4vw;
    }

    .subTitle {
        font-size: 5vw;
    }

    .btnTxt {
        font-size: 4vw;
    }

    .desName {
        font-size: 3vw;
    }

    .icon {
        width: 4vw;
        height: 4vw;
        margin-right: 2vw;
    }

    .iconA {
        width: 4vw;
        height: 4vw;
        margin-right: 2vw;
    }

    .contGrafica {
        width: 98vw;
        height: 80vh;
    }

    .cancha {
        width: 96vw;
        height: 30vh;
        margin-left: 2vw;
    }

    .canchaImg {
        width: 100%;
        height: 100%;
    }

    .areaPorteros {
        width: 25vw;
        height: 10vw;
        position: absolute;
        z-index: 2;
        bottom: 10vw;
        right: 35vw;

    }

    .areaDefensaCentrales {
        width: 20vw;
        height: 10vw;
        bottom: 21vw;
        right: 38vw;
    }

    .areaDefensaLateralIzquierdos {
        width: 20vw;
        height: 10vw;
        bottom: 24vw;
        right: 60vw;
    }

    .areaDefensaLateralDerechos {
        width: 20vw;
        height: 10vw;
        bottom: 24vw;
        right: 18vw;
    }

    .areaMediocentros {
        width: 20vw;
        height: 10vw;
        bottom: 39vw;
        right: 38vw;
    }

    .areaInteriorIzquierdos {
        width: 20vw;
        height: 10vw;
        bottom: 38vw;
        right: 17vw;
    }

    .areaInteriorDerechos {
        width: 20vw;
        height: 10vw;
        bottom: 38vw;
        right: 57vw;
    }

    .areaDelanteroCentrales {
        width: 20vw;
        height: 10vw;
        bottom: 55vw;
        right: 38vw;
    }

    .areaDelanteroLateralDerechos {
        width: 20vw;
        height: 10vw;
        bottom: 52vw;
        right: 20vw;
    }

    .areaDelanteroLateralIzquierdos {
        width: 20vw;
        height: 10vw;
        bottom: 52vw;
        right: 54vw;
    }

    .areaMedioCentroDefensivo {
        width: 20vw;
        height: 10vw;
        bottom: 31vw;
        right: 38vw;
    }

    .areaMedioCentroOfensivo {
        width: 20vw;
        height: 10vw;
        bottom: 47vw;
        right: 38vw;
    }

    .posicionCancha {
        width: 8vw;
        height: 8vw;
    }

    .lineOne {
        height: 30vh;
        top: 0px;
        right: 42vw;
    }

    .lineTwo {
        width: 340px;
        top: 15vh;
        right: 0px;
    }

    .btn {
        width: 150px;
        height: 40px;
        background-color: #E4FD3F;
        border-radius: 8px;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: center;
        color: #1C1D1E;
        cursor: pointer;
    }

    .btn:hover {
        background-color: #ffffff;
        color: #1C1D1E;
    }

    .contListaJugadores {
        align-items: flex-start;
        justify-content: flex-start;
        width: 96vw;
        height: 60vh;
        margin-left: 0px;
        margin-top: 5vw;
    }

    .contJugador {
        width: 96vw;
        height: 15vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }

    .contJugadorTwo {
        width: 96vw;
        height: 15vw;
        margin-top: 1vw;
        margin-bottom: 1vw;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .num {
        font-size: 4vw;
        margin-right: 4vw;
        margin-left: 2vw;
    }

    .foto {
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
    }

    .info {
        width: 50vw;
    }

    .name {
        font-size: 4vw;
    }

    .posicion {
        font-size: 3vw;
    }

    .bsdJugador {
        width: 11vw;
        height: 9vw;
    }

    .bsdTxtJugador {
        font-size: 2vw;
    }

    .bsdByJugador {
        font-size: 4vw;
    }

    .cerrar {
        display: none;
    }

    .btnAdd {
        width: 9vw;
        height: 9vw;
        margin-left: 2vw;
        margin-right: 1vw;
        font-size: 5vw;
        padding-bottom: 0px;
    }

    .iconB {
        width: 5vw;
        height: 3vw;
    }

    .botonera {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 98vw;
        height: 8vh;
        background-color: #262626;
        border-radius: 10px;
    }

    .btnMenu {
        width: 45vw;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2vw;
        margin-left: 2vw;
        
    }

    .btnMenuSelect {
        width: 45vw;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        margin-right: 2vw;
        margin-left: 2vw;
        border-radius: 10px;
        .txtMenu{
            color: #1C1D1E
        }
    }

    .txtMenu {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }

    .constDesactive{
        display: none;
    }

    .contSubTitleDes{
        display: none;
    }

    .contDesepenoDes{
        display: none;
    }

    .contGraficaDes{
        display: none;
    }

    .canchaDes{
        display: none;
    }

    .btnAgregar{
        display: block;
        width: 94vw;
        height: 6vh;
        background-color: #E4FD3F;
        color: #000000;
        border-radius: 10px;
        font-size: 4vw;
        font-weight: 600;
        position: fixed;
        bottom: 10vh;
        left: 3vw;
    }

    .back{
        position: fixed;
        top: 10vh;
        left: 3vw;
        font-family: Raleway;
        font-size: 5vw;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF; 
    }
}