.conteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    margin-top: 126px;
    z-index: 1;
}

.contMenu {
    display: flex;
    flex-direction: column;
    width: 306px;
    margin-left: 180px;
    margin-right: 90px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.42px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.contBtn {
    width: 306px;
    display: flex;
    flex-direction: column;
    background-color: #262626;
    border-radius: 8px;
    height: 150px;
}

.btnTxt {
    display: flex;
    width: 306px;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
}

.btnTxt:hover {
    background-color: #FFFFFF;
    cursor: pointer;

    .txtB {
        color: #262626
    }
}

.btnTxtActive {
    display: flex;
    width: 306px;
    height: 50px;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFFFFF;

    .txtB {
        font-family: Raleway;
        font-size: 18px;
        font-weight: 500;
        line-height: 21.13px;
        text-align: left;
        color: #262626;
        margin-left: 18px;
    }
}

.txtB {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.13px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 18px;
}

.contFiltros {
    display: flex;
    flex-direction: column;
    margin-top: 46px;
}

.contTitle {
    width: 306px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.subTitle {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.txtA {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #E4FD3F;
}

.txtA:hover {
    cursor: pointer;
    color: #FFFFFF
}

.parametro {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 11px;
    margin-top: 24px;
}

.inputCampo {
    width: 300px;
    height: 46px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
    margin-bottom: 14px;
}

.contPosicones {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contPossicionSelected {
    display: flex;
    flex-direction: row;
    max-width: 200px;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #262626;
    margin-bottom: 5px;
}

.posicion {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 5px;
}

.btnX {
    width: 21px;
    height: 21px;
    margin-left: 6px;
    margin-right: 5px;
}

.slider {
    .valor {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }
}

.contBotones {
    width: 306px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
}

.boton {
    width: 100px;
    height: 28px;
    border-radius: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
}

.botonSelected {
    width: 100px;
    height: 28px;
    border-radius: 12px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #000000;
}

.boton:hover {
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
}

.contBuscador {
    width: 691px;
    height: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 180px;
}

.input {
    width: 671px;
    height: 46px;
    border-radius: 8px;
    border: 2px solid #A4A4A4;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

input::placeholder {
    color: #A3A3A3;
    ;
}

.recomendados {
    width: 691px;
    height: 830px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
}

.contInput {
    display: flex;
}

.iconFilter {
    display: none;
}

.menuAnd {
    display: none;
}

.contMenuActive {
    display: flex;
    flex-direction: column;
    width: 306px;
    margin-left: 180px;
    margin-right: 90px;
}

.back {
    display: none;
}

@media screen and (max-width: 767px) {
    .conteiner {
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        margin-top: 10vh;
        margin-left: 2vw;
        z-index: 1;
    }

    .contMenu {
        display: none;
    }

    .contMenuActive {
        display: flex;
        flex-direction: column;
        width: 96vw;
        margin-left: 0px;
        margin-right: 0px;
        margin-left: 2vw;
        height: 100vh;
        overflow: scroll;
    }

    .title {
        display: none;
    }

    .contBtn {
        display: none;
    }

    .contFiltros {
        display: flex;
        flex-direction: column;
        margin-top: 1vh;
        width: 94vw;
    }

    .contTitle {
        width: 94vw;
    }

    .subTitle {
        font-size: 5vw;
    }

    .txtA {
        font-size: 3vw;
    }

    .parametro {
        font-size: 4vw;
        margin-bottom: 3vw;
        margin-top: 6vw;
    }

    .inputCampo {
        width: 94vw;
        height: 5vh;
        margin-bottom: 3vw;
    }

    .contPosicones {
        width: 94vw;
    }

    .contPossicionSelected {
        max-width: 90vw;
        height: 4vh;
        margin-bottom: 1vw;
    }

    .posicion {
        font-size: 3vw;
        margin-left: 1vw;
    }

    .btnX {
        width: 5vw;
        height: 5vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

    .slider {
        width: 90vw;
        margin-left: 2vw;

        .valor {
            font-size: 4vw;
        }
    }

    .contBotones {
        width: 94vw;
        margin-bottom: 4vh;
    }

    .boton {
        width: 25vw;
        height: 4vh;
        font-size: 4vw;
    }

    .botonSelected {
        width: 25vw;
        height: 4vh;
        font-size: 4vw;
        background-color: #E4FD3F;
    }


    .contBuscador {
        width: 94vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0px;
        margin-top: 0px;
    }

    .menuAnd {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 94vw;
        margin-left: 0px;
        margin-bottom: 3vh;
        margin-top: 30vh;
    }

    .opc {
        width: 25vw;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .opcSelected {
        width: 25vw;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 8px;

        .txtOpc {
            color: #000000;
        }
    }

    .txtOpc {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }

    .contInput {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 94vw;
        align-items: center;
    }

    .input {
        width: 74vw;
        height: 6vh;
        font-size: 4vw;
        margin-bottom: 5vw;
        margin-left: 3vw;
    }

    .recomendados {
        width: 100vw;
        height: 100vh;
    }

    .iconFilter {
        width: 8vw;
        height: 5vw;
        margin-bottom: 4vw;
        margin-right: 3vw;
        display: flex;
    }

    .blockDesactive {
        display: none;
    }

    .back {
        display: flex;
        font-family: Raleway;
        font-size: 4vw;
        font-weight: 600;
        line-height: 17.61px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 5vw;
    }
}