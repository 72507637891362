.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/Imagen\ Lateral\ Fondo.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 25vw 100vh;
}

.contAviso {
    display: flex;
    margin-bottom: 15px;
}

.aviso {
    color: white;
}

.avisoB {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.general {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url("../../assets/FondoBack.png");
    background-position: center left 25vw;
    background-repeat: no-repeat;
    background-size: 75vw 100vh;
}

.contLogo {
    display: flex;
    width: 390px;
    height: 76px;
}

.logo {
    position: absolute;
    left: 900px;
    top: 20px;
    width: 150px;
    height: 40px;
}

.logo:hover {
    cursor: pointer;
}

.sesion {
    width: 416px;
    height: 429px;
    top: 96px;
    left: 787px;
    gap: 25px;
    margin-left: 25vw;
    margin-top: 40px;
    z-index: 2;
}

.inter {
    display: flex;
    flex-direction: column;
}

.regName {
    color: white;
    font-size: 38px;
    font-weight: 400;
    line-height: 50.4px;
    text-align: left;
    margin-bottom: 2px;
}

.conText {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.linkText {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: left;
    margin-right: 4px;
}

.inputs {
    width: 416px;
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    background-color: #262626;
    border-radius: 12px;
    color: #FFFFFF;
}

.inputs:hover {
    background-color: #444444;
    cursor: pointer;
}

.info {
    width: 325px;
    height: 60px;
}

.icon {
    width: 40px;
    height: 40px;
}


.inp:hover {
    background-color: #444444;
}

.etiqueta {
    width: 63px;
    height: 19px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.78px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.textInfo {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
}

.btn {
    width: 416px;
    height: 46px;
    gap: 0px;
    border-radius: 8px;
    background-color: #E4FD3F;
    color: black;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    margin-bottom: 15px;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.copyright {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-top: auto;
    margin-left: 25vw;
}

.basica {
    width: 636px;
    height: 698px;
    top: 171px;
    left: 677px;
    margin-left: 25vw;
    margin-top: 20px;
    z-index: 2;
}

.contFoto {
    width: 636px;
    height: 90px;
    margin-bottom: 2px;
}

.contSubida {
    display: flex;
    flex-direction: row;
}

.descripcion {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 7px;
}

.foto {
    width: 230px;
    height: 80px;
    padding: 0px;
    border-radius: 8px;
}

.fotoPerfil {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-left: 30px;
}

.nacimiento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
}

.contSelect {
    display: flex;
    flex-direction: row;
}

.selector {
    width: 150px;
    height: 30px;
    border-radius: 8px;
    justify-content: space-between;
    background-color: #262626;
    color: #A4A4A4;
}

.selector:hover {
    background-color: #444444;
    cursor: pointer;
}

.selectorM {
    width: 316px;
    height: 30px;
    border-radius: 8px;
    justify-content: space-between;
    background-color: #262626;
    color: #A4A4A4;
    margin-left: 10px;
    margin-right: 10px;
}

.selectorM:hover {
    background-color: #444444;
    cursor: pointer;
}

.cont {
    display: flex;
    flex-direction: row;
    width: 636px;
    height: 64px;
}

.contB {
    display: flex;
    flex-direction: row;
    width: 636px;
    height: 60px;
}

.camp {
    display: flex;
    flex-direction: column;
    width: 305px;
    height: 74px;
}

.descripcionCamp {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.contInp {
    display: flex;
    flex-direction: row;
}

.inputCamp {
    width: 272px;
    height: 30px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
}

.medida {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 14px;
    margin-left: 10px;
    margin-right: 14px;
    margin-bottom: 14px;
}


.contCheck {
    display: flex;
    flex-direction: row;
}

.linkTextCheck {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    margin-right: 4px;

}

.linkedCheck {
    color: #E4FD3F;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
}

.linkedCheck:hover {
    color: #FFFFFF;
}

.checkbox {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 5px;
    background-color: #262626;
    cursor: pointer;
}

.contPie {
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;
}

.contOpcionesPie {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxP {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid #262626;
    background-color: #262626;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}

.checkboxP:checked {
    background-color: #E4FD3F;
    /* border-color: #f5f5f5; */
    border: 4px solid rgb(62, 62, 62);
}

.labelCheckbox {
    font-size: 12px;
    color: #333;
}

.labelP {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
    margin-right: 50px;
}

.btnContinuar {
    width: 305px;
    height: 36px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-left: 25px;
    margin-top: 15px;
}

.btnContinuar:hover {
    background-color: #FFFFFF;
    cursor: pointer;
}

.btnRegresar {
    width: 305px;
    height: 36px;
    border-radius: 8px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
}

.btnRegresar:hover {
    background-color: #444444;
    cursor: pointer;
}

.uploadButton {
    width: 180px;
    height: 46px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 15px;
    margin-left: 20px; /* Mueve hacia la izquierda */
    cursor: pointer;
}



.posicion {
    width: 636px;
    height: 698px;
    top: 171px;
    left: 677px;
    margin-left: 25vw;
    margin-top: 20px;
    z-index: 2;
}

.campo {
    width: 400px;
    height: 163px;
    margin-left: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contOpciones {
    display: flex;
    flex-direction: column;
    width: 636px;
    height: 232px;
    margin-bottom: 30px;
}

.opciones {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    justify-content: space-around;
    width: 636px;
    height: 232px;
}

.opcion {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.selectOpc {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.23px;
    text-align: left;
    color: #FFFFFF;
}

.selectOpcTwo {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.23px;
    text-align: left;
    color: #787878;
}

.contCheck {
    width: 215px;
    height: 30px;
}

.contCheckb {
    width: 636px;
    height: 30px;
    margin-bottom: 15px;
}

.textCheck {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    margin-right: 4px;
    cursor: pointer;
}

.habilidades {
    width: 636px;
    height: 698px;
    margin-left: 25vw;
    margin-top: 15vh;
    z-index: 2;
}

.opcionh {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.metricas {
    width: 636px;
    height: 900px;
    top: 171px;
    left: 677px;
    margin-left: 25vw;
    margin-top: 20px;
    z-index: 2;
}

.regNameM {
    color: white;
    font-size: 32px;
    font-weight: 400;
    line-height: 40.4px;
    text-align: left;
    margin-bottom: 5px;
}

.contCalificacion {
    width: 636px;
    height: 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.contCali {
    display: flex;
    flex-direction: row;
    width: 636px;
    height: 20px;
}

.selectOpcM {
    font-family: Oswald;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.23px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.cali {
    display: flex;
    width: 120px;
    height: 20px;
    border-radius: 6px;
    background-color: #262626;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.cali:hover {
    cursor: pointer;
    background-color: #444444;
}

.caliSeleccionado {
    display: flex;
    width: 120px;
    height: 20px;
    border-radius: 6px;
    background-color: #E4FD3F;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.caliText {
    color: #FFFFFF;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.75px;
    text-align: left;
}

.conTextM {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.textInfoM {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.61px;
    margin-bottom: 5px;
}

.linkTextM {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;
    margin-right: 4px;
}

.listo {
    width: 363px;
    height: 254px;
    margin-top: 185px;
    margin-left: 400px;
    z-index: 2;
}

.interL {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filled {
    width: 54px;
    height: 54px;
    margin-bottom: 25px;
}

.regNameL {
    color: white;
    font-size: 42px;
    font-weight: 400;
    line-height: 50.4px;
    margin-bottom: 10px;
}

.linkTextL {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    text-align: center;
    margin-bottom: 25px;
}

.intereses {
    width: 636px;
    height: 635px;
    top: 96px;
    left: 787px;
    gap: 25px;
    margin-left: 25vw;
    margin-top: 40px;
    z-index: 2;
}

.inputsI {
    width: 616px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    background-color: #262626;
    border-radius: 12px;
    padding-right: 18px;
    color: #FFFFFF;
}

.inputsI:hover {
    background-color: #444444;
    cursor: pointer;
}

.infoI {
    width: 400px;
    height: 60px;
    margin-left: 18px;
}

.textInfoI {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
}

.etiquetaI {
    width: 200px;
    height: 19px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.78px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.basicao {
    width: 636px;
    height: 698px;
    top: 171px;
    left: 677px;
    margin-left: 25vw;
    margin-top: 10vh;
    z-index: 2;
}

.conto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 636px;
    height: 64px;
}

.inputCampo {
    width: 305px;
    height: 36px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
}


@media screen and (max-width: 767px) {
    .container {
        background-image: none;
        height: auto;
    }

    .general {
        background-size: 100vw 100vh;
        background-position: center;
    }

    .sesion {
        width: 100vw;
        height: auto;
        margin-top: 30vw;
        margin-left: 0px;
        overflow: scroll;
    }

    .basica {
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll;
    }

    .basicao {
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll;
    }

    .posicion{
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll;
    }

    .listo{
        width: 100vw;
        height: auto;
        margin-top: 50vw;
        margin-left: 0px;
        overflow: scroll;  
    }

    .habilidades{
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll;
    }

    .intereses{
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll; 
    }

    .metricas{
        width: 100vw;
        height: auto;
        margin-top: 10vw;
        margin-left: 0px;
        overflow: scroll; 
    }

    .inter {
        width: 80vw;
        margin-left: 10vw;
        margin-right: 10vw;
        align-items: flex-start;
    }

    .campo {
        width: 80vw;
        height: 40vw;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .contLogo {
        display: flex;
        justify-content: center;
    }

    .logo {
        width: 30vw;
        height: 15vw;
        left: 40vw;
        top: 3vw;
    }

    .regName {
        color: #FFFFFF;
        font-size: 8vw;
        line-height: 12vw;
        text-align: left;
        margin-bottom: 1vw;
    }

    .conText {
        width: 80vw;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 5vw;
    }

    .linkText {
        font-size: 3.5vw;
        line-height: 5vw;
        text-align: left;
        margin-right: 1vw;
    }

    .linked {
        font-size: 3vw;
        line-height: 4vw;
        text-align: left;
    }

    .contFoto {
        width: 80vw;
        height: auto;
        margin-bottom: 5vw;
    }

    .descripcion {
        font-size: 4vw;
        line-height: 5vw;
        margin-bottom: 3vw;
    }

    .contSubida {
        width: 80vw;
    }

    .uploadButton {
        width: 80vw;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        text-align: center;
        font-size: 4vw;
        line-height: 5vw;
        margin-bottom: 5vw;
        margin-top: 5vw;
        
    }

    .nacimiento {
        width: 80vw;
        align-items: flex-start;
        margin-bottom: 5vw;
    }

    .contSelect {
        width: 80vw;
        flex-direction: column;
    }

    .selector {
        width: 80vw;
        height: 10vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    .selectorM {
        width: 80vw;
        height: 10vw;
        margin-left: 0px;
    }

    .contPie {
        width: 80vw;
    }

    .contOpcionesPie {
        width: 80vw;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 5vw;
    }

    .opt {
        display: flex;
        flex-direction: row;
        margin-top: 1vw;
        margin-bottom: 1vw;
        align-items: center;
    }

    .checkboxP {
        width: 5vw;
        height: 5vw;
        margin: 0px;
    }

    .labelP {
        font-size: 4vw;
        line-height: 0px;
        margin-right: 0px;
        margin-left: 5vw;
    }

    .cont {
        width: 80vw;
        height: auto;
        flex-direction: column;
    }

    .conto{
        width: 80vw;
        height: auto;
        flex-direction: column;
    }

    .camp {
        width: 80vw;
        height: 20vw;
    }

    .descripcionCamp {
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .inputCamp {
        width: 80vw;
        height: 9vw;
    }

    .medida {
        font-size: 4vw;
        margin-top: 3vw;
        margin-left: 2vw;
        margin-right: 4vw;
        margin-bottom: 4vw;
    }

    .contCheckb{
        width: 80vw;
        height: auto;
        margin-bottom: 0px;
    }

    .contB{
        width: 80vw;
        height: auto;
        margin-bottom: 10vw;
        margin-top: 5vw;
        justify-content: space-between;
    }

    .btnRegresar{
        width: 35vw;
        height: 10vw;
        margin-left: 0px;
        margin-top: 0px;
        font-size: 4vw;
    }

    .btnContinuar{
        width: 35vw;
        height: 10vw;
        margin-left: 0px;
        margin-top: 0px;
        font-size: 4vw;
    }

    .contOpciones{
        width: 80vw;
        height: auto;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .selectOpc{
        font-size: 4vw;
        margin-bottom: 3vw;
    }

    .opciones{
        width: 80vw;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .opcion{
        flex-direction: row;
        margin: 0px;
    }

    .contCheck{
        width: 80vw;
        height: 9vw;
    }

    .checkbox{
        width: 4vw;
        height: 4vw;
        margin-right: 4vw;
    }

    .textCheck{
        font-size: 4vw;
        margin-right: 0px;
    }

    .selectOpcTwo{
        font-size: 4vw;
    }

    .avisoB{
        font-size: 3vw;
        margin-top: 3vw;
    }

    .contCalificacion{
        width: 80vw;
        height: auto;
        margin-bottom: 3vw;
    }

    .selectOpcM{
        font-size: 3vw;
        margin-bottom: 2vw;
    }

    .textInfoM{
        font-size: 3vw;
    }

    .contCali{
        width: 80vw;
        height: auto;
        justify-content: space-between;
    }

    .cali{
        width: 14vw;
        height: 6vw;
        margin: 0px;
    }

    .caliText{
        font-size: 3vw;
    }

    .inputsI{
        width: 80vw;
        height: 25vw;
    }

    .infoI{
        width: 60vw;
        height: auto;
        margin-left: 3vw;
    }

    .etiquetaI{
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .textInfoI{
        font-size: 4vw;
    }

    .icon{
        width: 10vw;
        height: 10vw;
    }

    .foto {
        width: 70vw;
        height: 22vw;
    }

    .inputs {
        width: 80vw;
        height: 25vw;
        margin-top: 3vw;
        margin-bottom: 0px;
    }

    .info{
        width: 60vw;
        height: auto;
        margin-left: 3vw;  
    }

    .etiqueta {
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .textInfo{
        font-size: 4vw;
    }

    .inp {
        width: 342px;
        height: 46px;
        margin-bottom: 25px;
        color: #FFFFFF;
    }

    .btn {
        width: 342px;
        height: 46px;
    }

    .copyright {
        font-size: 12px;
        margin-left: 0px;
    }
}