.conteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 900px;
    height: 550px;
    z-index: 1;
}

.contMenu {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-left: 20px;
    margin-top: 20px;
    overflow-y: auto;
    scrollbar-width: none;
}

.contBuscador {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.42px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 28px;
}

.contFiltros {
    display: flex;
    flex-direction: column;
}

.listaJugadores{
    display: flex;
    flex-direction: column;
}

.constDesactive{
    display: flex;
    flex-direction: column;  
}

.subTitle {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.txtA {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #E4FD3F;
}

.txtA:hover {
    cursor: pointer;
    color: #FFFFFF
}

.contTitle {
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.parametro {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-top: 5px;
}

.inputCampo {
    width: 395px;
    height: 26px;
    border-radius: 8px;
    background-color: #262626;
    color: #A4A4A4;
    margin-bottom: 14px;
    margin-left: 3px;
}

.icon{
    width: 20px;
    height: 13px;
    margin-bottom: 5px;
}

.contPosicones {
    width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: start;
}

.contPossicionSelected {
    display: flex;
    flex-direction: row;
    max-width: 190px;
    height: 25px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #262626;
    margin-bottom: 5px;
}

.posicion {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 5px;
}

.btnX {
    width: 21px;
    height: 21px;
    margin-left: 6px;
    margin-right: 5px;
    cursor: pointer;
}

.btnX:hover {
    filter: invert(100%) sepia(100%) saturate(10000%);
}

.slider {
    width: 380px;
    margin-left: 10px;

    .valor {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }
}

.contBotones {
    width: 306px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
}

.boton {
    width: 100px;
    height: 28px;
    border-radius: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #FFFFFF;
}

.botonSelected {
    width: 100px;
    height: 28px;
    border-radius: 12px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: center;
    color: #000000;
}

.boton:hover {
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
}

.input {
    width: 390px;
    height: 36px;
    border-radius: 8px;
    border: 2px solid #A4A4A4;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

input::placeholder {
    color: #A3A3A3;
    ;
}

.recomendados {
    width: 400px;
    height: 380px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
}

.cerrar {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.42px;
    text-align: right;
    color: #FFFFFF;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 35px;
    cursor: pointer;
}

.cerrar:hover {
    color: #E4FD3F;
}


.contJugador {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 60px;
    justify-content: left;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.contJugadorTwo {
    display: flex;
    flex-direction: row;
    width: 400px;
    height: 60px;
    justify-content: left;
    align-items: center;
    background-color: #353535;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.num {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 30px;
    margin-left: 10px;
}

.foto {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px;
    border-style: solid;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
}

.name {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.posicion {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.bsdJugador {
    width: 45px;
    height: 40px;
    display: flex;
    flex-direction: column;
    background-color: #E4FD3F;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: center;
    justify-content: center;
}

.bsdTxtJugador {
    font-family: Oswald;
    font-size: 8.02px;
    font-weight: 400;
    line-height: 11.88px;
    text-align: center;
    color: #1C1D1E;
}

.bsdByJugador {
    font-family: Oswald;
    font-size: 17.27px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
    color: #1C1D1E;
}

.btnAdd {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    background-color: #1C1D1E;
    text-align: center;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 5px;
    font-family: Oswald;
    font-size: 24px;
    padding-bottom: 2px;
}

.btnAdd:hover{
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
    .icon{
        filter: invert(1) sepia(100%) saturate(10000%);
    }
}

.contFiltrar{
    display: none;
}

.btnAgregar{
    display: none;
}

.btnTtxtB{
    display: none;
}

@media screen and (max-width: 767px) {
    .conteiner {
        flex-direction: column;
        justify-content: flex-start;
        width: 98vw;
        height: 90vh;
    }
    
    .contMenu {
        width: 98vw;
        margin-left: 0px;
        margin-top: 4vw;
    }
    
    .btnTtxtB{
        display: block;
        font-size: 4vw;
        margin-top: 3vh;
        margin-right: auto;
        margin-left: 1vw;
    }

    .listaJugadores{
        display: flex;
        flex-direction: column;
        width: 98vw;
    }
    
    .contBuscador {
        width: 98vw;
        margin-right: 0px;
    }
    
    .title {
        font-size: 8vw;
        margin-bottom: 5vw;
    }
    
    .contFiltros {
        display: flex;
        flex-direction: column;
        overflow: visible;
        scrollbar-width: none;
        padding-bottom: 15vh;
    }
    
    .subTitle {
        font-size: 5vw;
    }
    
    .txtA {
        font-size: 4vw;
    }
    
    .contTitle {
        width: 98vw;
        margin-bottom: 2vw;
    }
    
    .parametro {
        font-size: 4vw;
        margin-bottom: 2vw;
        margin-top: 2vw;
        margin-left: 2vw;
    }
    
    .inputCampo {
        width: 96vw;
        height: 5vh;
        margin-bottom: 2vw;
        margin-left: 1vw;
    }
    
    .icon{
        width: 5vw;
        height: 3vw;
        margin-bottom: 1vw;
    }
    
    .contPosicones {
        width: 96vw;
        margin-left: 1vw;
    }
    
    .contPossicionSelected {
        max-width: 47vw;
        height: 4vh;
        margin-bottom: 1vw;
        padding-left: 3vw;
        justify-content: space-between;
        align-items: center;
    }
    
    .posicion {
        font-size: 3vw;
    }
    
    .btnX {
        width: 21px;
        height: 21px;
        margin-left: 6px;
        margin-right: 5px;
        cursor: pointer;
    }
    
    .btnX:hover {
        filter: invert(100%) sepia(100%) saturate(10000%);
    }
    
    .slider {
        width: 91vw;
        margin-left: 3vw;
        margin-top: 2vw;
    
        .valor {
            font-size: 4vw;
        }
    }
    
    .contBotones {
        width: 98vw;
        margin-bottom: 30px;
        margin-top: 1vw;
    }
    
    .boton {
        width: 30vw;
        height: 4vh;
        font-size: 14px;
    }
    
    .botonSelected {
        width: 100px;
        height: 28px;
        border-radius: 12px;
        background-color: #E4FD3F;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.44px;
        text-align: center;
        color: #000000;
    }
    
    .boton:hover {
        cursor: pointer;
        background-color: #E4FD3F;
        color: #000000;
    }
    
    .input {
        width: 98vw;
        height: 10vw;
        font-size: 4vw;
        margin-bottom: 4vw;
    }
    
    .recomendados {
        width: 98vw;
        height: 50vh;
    }
    
    .cerrar {
       display: none;
    }
    
    .cerrar:hover {
        color: #E4FD3F;
    }
    
    
    .contJugador {
        width: 98vw;
        height: 10vh;
        margin-top: 1vw;
        margin-bottom: 1vw;
    }
    
    .contJugadorTwo {

        width: 98vw;
        height: 10vh;
        margin-top: 1vw;
        margin-bottom: 1vw;
        padding-bottom: 0px;
        padding-top: 0px;
    }
    
    .num {
        font-size: 4vw;
        margin-right: 3vw;
        margin-left: 2vw;
    }
    
    .foto {
        width: 12vw;
        height: 12vw;
        margin-right: 2vw;
    }
    
    .info {
        width: 45vw;
    }
    
    .name {
        font-size: 3vw;
    }
    
    .posicion {
        font-size: 3vw;
        margin-left: 0;
        text-align: left;
    }
    
    .bsdJugador {
        width: 10vw;
        height: 10vw;
        margin-right: 1vw;
    }
    
    .bsdTxtJugador {
        font-size: 2vw;
    }
    
    .bsdByJugador {
        font-size: 4vw;
    }
    
    .btnAdd {
        width: 9vw;
        height: 9vw;
        margin-left: 1vw;
        margin-right: 1vw;
        font-size: 7vw;
        padding-bottom: 1vw;
    }

    .constDesactive {
        display: none;
    }

    .contFiltrar{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 98vw;
    margin-top: 2vw;
    margin-bottom: 5vw;
    margin-left: 3vw;
    }

    .iconFiltrar{
        width: 8vw;
        height: 6vw;
        filter: invert(10%) sepia(100%) saturate(10000%);
    }

    .txtFiltrar{
        font-size: 6vw;
        margin-left: 3vw;
        font-weight: 600;
        line-height: 16.44px;
        text-align: center;
        color:#E4FD3F;
    }

    .btnAgregar{
        display: block;
        width: 94vw;
        height: 6vh;
        background-color: #E4FD3F;
        color: #000000;
        border-radius: 10px;
        font-size: 4vw;
        font-weight: 600;
        position: fixed;
        bottom: 10vh;
        left: 3vw;
    }
}