.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    margin-top: 96px;
    z-index: 1;
}

.partOne{
    display: flex;
    width: 25vw;
}

.partTwo{
    display: flex;
    width: 30vw;
}

.partThre{
    display: flex;
    width: 30vw;
}

.navSecond{
    display: none;
}

.partDesactive{
    display: flex;
    width: 30vw;
}

@media screen and (max-width: 767px) {
    .container{
        flex-direction: column;
        justify-content:space-between;
        align-items: center;
        margin-top: 10vh;
        width: 100vw;
    }

    .partOne{
        display: flex;
        width: 94vw;
    }

    .partTwo{
        display: flex;
        width: 94vw; 
    }

    .partThre{
        display: flex;
        width: 94vw;
    }

    .partDesactive{
        display: none;
    }

    .navSecond{
        display: flex;
        width: 100vw;
    }
}