.noticias {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
    height: 600px;
    margin-top: 20px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1200px;
    height: 550px;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.42px;
    text-align: center;
    color: #FFFFFF;
}

.subTitle {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.contFoto {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 600px;
    margin-top: 30px;
    cursor: pointer;
}

.cont {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100px;
    margin-right: 20px;
}

.seccionName {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 12px;
}

.contNoticias {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 600px;
    height: 500px;
    border: 2px solid rgb(75, 75, 75);
}

.foto {
    width: 200px;
    height: 80px;
    margin-right: 15px;
    border-radius: 5px;
    margin-left: 15px;
}

.inp {
    display: flex;
    width: 250px;
    height: 40px;
    border-radius: 8px;
    margin-left: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
}

.contVista {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 600px;
    height: 500px;
    border: 2px solid rgb(75, 75, 75);
}

.contData {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 600px;
    height: 100px;
    margin-top: 10px;
}

.botonera{
    display: flex;
    flex-direction: row;
}

.btn {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    background-color: #262626;
    border-radius: 8px;
    width: 100px;
    margin-left: 10px;
    height: 45px;
    cursor: pointer;
}

.btn:hover {
    background-color: #E4FD3F;
    color: #000000;
}


.btnA {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
    background-color: #E4FD3F;
    border-radius: 8px;
    width: 300px;
    height: 45px;
    margin-top: 30px;
    cursor: pointer;
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 400px;
    margin-top: 10px;
    background-color: #262626;
    border-radius: 12px;
}

.contTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 400px;
    margin-top: 10px;
}

.imgNoticia{
    width: 380px;
    height: 200px;
    border-radius: 12px;
}

.video{
    width: 380px;
    height: 200px;
    border-radius: 12px;
}

.logo {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 50%;
}

.data {
    display: flex;
    flex-direction: column;
    width: 150px;
}

.name {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.link {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.time {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: right;
    color: #A4A4A4;
    margin-left: 60px;
}

.contenido {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 380px;
    /* height: 300px; */
    margin-top: 10px;
}

.contTxt {
    display: flex;
    width: 370px;
    margin-left: 10px;
    margin-bottom: 15px
}

.txt {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
}

.reacciones {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 380px;
    height: 50px;
}

.reaccion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reaccionB {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 120px;
}

.value {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    margin-right: 10px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
}

.iconB {
    margin-right: 5px;
    margin-left: 10px;
    width: 50px;
    height: 40px;
    margin-top: 5px;
}
/* Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
  }
  
  .modalContent {
    background-color:#262626;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .modal p {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .modal button {
    background-color:  #E4FD3F;
    color:#333;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .modal button:hover {
    background-color: #E4FD3F;
  }
  
  .modal button:focus {
    outline: none;
  }
  
  .modal button:first-child {
    background-color: #e3e0e0;
  }
  
  .modal button:first-child:hover {
    background-color: #999;
  }
  