.listas {
    width: 436px;
    height: 1100px;
    /* overflow-y: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    border-radius: 10px;
    margin-right: 70px;
    /* scrollbar-width: none; */
    z-index: 2;
}

.contTitle {
    display: flex;
    flex-direction: row;
    width: 436px;
    justify-content: space-between;
    align-items: flex-end;
}

.btnAdd {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    background-color: #1C1D1E;
    text-align: center;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 5px;
    font-family: Oswald;
    font-size: 20px;
    padding-bottom: 2px;
}

.btnAdd:hover{
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
    .iconB{
        filter: invert(1) sepia(100%) saturate(10000%);
    }
}


.iconB{
    width: 20px;
    height: 13px;
}


.title {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.btnTxt {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #E4FD3F;
    cursor: pointer;
}

.btnTxt:hover {
    color: #FFFFFF;
}

.addLista {
    display: flex;
    flex-direction: column;
    width: 436px;
    align-items: flex-start;
    margin-top: 15px;
}

.txt {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 5px;
}

.inp {
    width: 430px;
    height: 30px;
    border-radius: 8px;
    background-color: #0F0F0F;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.botonera {
    display: flex;
    flex-direction: row;
    width: 430px;
    justify-content: space-between;
    margin-bottom: 15px;
}

.btn {
    width: 200px;
    height: 30px;
    border-radius: 8px;
    background-color: #0F0F0F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.btn:hover {
    background-color: #444444;
}

.btnA {
    width: 200px;
    height: 30px;
    border-radius: 8px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btnA:hover {
    background-color: #FFFFFF;
}

.contListas {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 436px;
    height: 1100px;
    /* overflow-y: auto; */
    margin-top: 15px;
    border-radius: 10px;
    /* scrollbar-width: none; */
    z-index: 2;
}

.cuestion {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.btnC{
    width: 240px;
    height: 35px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    margin-top: 15px;
    cursor: pointer;
}

.contLista {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 430px;
    margin-top: 15px;
    border-radius: 8px;
    background-color: #262626;
}

.listVista {
    display: flex;
    flex-direction: row;
    width: 420px;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contBtnTxt {
    width: 120px;
    height: 55px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 10px;
}

.listData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    margin-left: 10px;
}

.listName {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.listDesc {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.contList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 410px;
    height: 300px;
    overflow-y: auto;
    margin-top: 15px;
    border-radius: 10px;
    scrollbar-width: none;
    z-index: 2;
}

.list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    width: 410px;
    height: 45px;
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.contDataUser {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 230px;
    height: 35px;
}

.img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #E4FD3F;
}

.name {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.posicion {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.contBSD {
    display: flex;
    flex-direction: column;
    width: 55px;
    height: 40px;
    background-color: #E4FD3F;
    margin-left: 80px;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.bsdTxt {
    font-family: Oswald;
    font-size: 9.29px;
    font-weight: 400;
    line-height: 13.76px;
    text-align: center;
    color: #1C1D1E;
}

.bsdValor {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.64px;
    text-align: center;
    color: #1C1D1E;
}

.modalListaFija{
    display: flex;
    width: 436px;
    height: 500px;
    margin-top: 20px;
    padding-top: 20px;
}

@media screen and (max-width: 767px) {
    .listas {
        width: 94vw;
        height: 100vh;
        margin-top: 0px;
        margin-right: 0px;
    }

    .modalListaFija{
        display: none;
    }
    
    .contTitle {
        width: 92vw;
    }
    
    .btnAdd {
        width: 10vw;
        height: 10vw;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 6vw;
        padding-bottom: 0px;
    }
    
    .iconB{
        width: 20px;
        height: 13px;
    }
    
    .title {
        font-size: 6vw;
    }
    
    .btnTxt {
        font-size: 5vw;
    }
    
    .addLista {
        width: 94vw;
        margin-top: 4vw;
    }
    
    .txt {
        font-size: 5vw;
        margin-bottom: 3vw;
        margin-left: 1vw;
    }
    
    .inp {
        width: 92vw;
        height: 8vw;
        font-size: 4vw;
        margin-bottom: 15px;
    }
    
    .botonera {
        width: 92vw;
        margin-bottom: 0px;
        margin-top: 5vw;
    }
    
    .btn {
        width: 44vw;
        height: 9vw;
        font-size: 5vw;
    }
    
    .btnA {
        width: 44vw;
        height: 9vw;
        font-size: 5vw;
    }
    
    .contListas {
        width: 92vw;
        height: 80vh;
        margin-top: 5vw;
    }
    
    .cuestion {
        font-size: 6vw;
    }
    
    .btnC{
        width: 70vw;
        height: 10vw;
        font-size: 5vw;
        margin-top: 5vw;
    }
    
    .contLista {
        width: 92vw;
        margin-top: 4vw;
    }
    
    .listVista {
        width: 92vw;
        height: 8vh;
        margin-top: 3vw;
        margin-bottom: 3vw;
    }
    
    .contBtnTxt {
        width: 30vw;
        height: 7vh;
        margin-right: 4vw;
    }
    
    .listData {
        width: 55vw;
        margin-left: 3vw;
    }
    
    .listName {
        font-size: 4vw;
        margin-bottom: 3vw;
    }
    
    .listDesc {
        font-size: 4vw;
    }
    
    .contList {
        width: 90vw;
        height: 30vh;
        margin-top: 4vw;
    }
    
    .list {
        width: 90vw;
        height: 12vw;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
    
    .contDataUser {
        width: 55vw;
        height: 11vw;
        justify-content: center;
    }
    
    .img {
        width: 10vw;
        height: 10vw;
        margin-right: 2vw;
    }
    
    .name {
        font-size: 4vw;
    }
    
    .posicion {
        font-size: 3vw;
    }
    
    .contBSD {
        width: 15vw;
        height: 10vw;
        margin-left: 5vw;
    }
    
    .bsdTxt {
        font-size: 3vw;
    }
    
    .bsdValor {
        font-size: 5vw;
    }
}

