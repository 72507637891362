.contacto {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding-top: 80px;
    padding-left: 160px;
    padding-bottom: 150px;
    background-image: url("../../../assets/gradienteC.png");
    background-repeat: no-repeat;
    background-size: 900px 900px;
}

.contTitle {
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: flex-start;
    justify-content: left;
    margin-top: 120px;
}

.contForm {
    display: flex;
    flex-direction: column;
    width: 500px;
    align-items: flex-start;
    justify-content: left;
    margin-left: 120px;
}

.title {
    font-family: Oswald;
    font-size: 54px;
    font-weight: 600;
    line-height: 75.6px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.txt {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    color: #FFFFFF;
}

.form {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 500px;
    align-items: flex-start;
    justify-content: left;
    background-color: #FFFFFF;
    padding-top: 40px;
    border-radius: 20px;
}

.campo {
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: flex-start;
    justify-content: left;
    margin-bottom: 20px;
    margin-left: 10px;
}

.txtInp {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 700;
    line-height: 21.61px;
    text-align: left;
    color: #262626;
    margin-left: 15px;
}

.txtInpError {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 700;
    line-height: 21.61px;
    text-align: left;
    color: #c61a1a;
    margin-left: 15px;
}

.inp {
    width: 400px;
    height: 40px;
    border-radius: 8px;
    margin-left: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.inpError {
    width: 400px;
    height: 40px;
    border-radius: 8px;
    margin-left: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    border: 2px solid red;
}

.aviso {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.6px;
    text-align: left;
    color: #262626;
}

.inpTxt {
    width: 395px;
    height: 80px;
    border-radius: 8px;
    margin-left: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    padding-left: 10px;
    padding-top: 5px;
}

.botones {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 25px;
}

.btn {
    width: 150px;
    height: 40px;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    background-color: #E4FD3F;
    color: #1C1D1E;
    border: none;
    cursor: pointer;
}

.btn:hover {
    background-color: #262626;
    color: #FFFFFF;
}

@media only screen and (max-width: 767px) {
    .contacto{
        margin-top: 0px;
        margin-bottom: 450px;
        align-items: center;
        height: auto;
        margin-bottom: 0px;
    }
    .container{
        flex-direction: column;
        padding-left: 0px;
        padding-top: 0px;
        align-items: center;
        padding-bottom: 25vw;
    }
    .contTitle{
        margin-top: 25vw;
        margin-bottom: 15vw;
        width: 100%;
        align-items: center;
    }
    .title{
        text-align: center;
        font-size: 12vw;
        line-height: 12vw;
        margin-bottom: 15vw;
    }
    .txt{
        text-align: center;
        font-size: 5vw;
        line-height: 6vw;
    }
    .contForm{
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 0px;
        align-items: center;
    }
    .form{
        width: 90vw;
        height: auto;
        padding-bottom: 10vw;
    }
    .campo {
        width: 80vw;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 5vw;
        align-items: center;
        justify-content: center;
    }
    .inp {
        width: 75vw;
        height: 6vw;
        font-size: 3vw;
        margin-left: 0px;
    }
    .inpError{
        width: 95%;
        height: 40px;
        font-size: 3vw;
    }
    .inpTxt{
        width: 75vw;
        height: 15vw;
        font-size: 3vw;
        line-height: 4vw;
        margin-left: 0px;
    }
    .botones{
        width: 80vw;
        margin-top: 5vw;
        margin-left: 5vw;
        margin-right: 5vw;
    }
    .btn{
        width: 35vw;
        font-size: 4vw;
        height: 10vw;
    }
    .aviso{
        font-size: 4vw;
    }
    .txtInp{
        font-size: 3vw;
        line-height: 3vw;
        margin-left: 0px;
        margin-bottom: 1vw;
    }
    .txtInpError{
        font-size: 3vw;
    }
}
