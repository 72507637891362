.container {
  position: fixed;
  overflow-y: auto;
  scrollbar-width: none;
  top: 88px;
  right: 150px;
  width: 400px;
  height: 380px;
  background-color: #262626;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  display: block;
  z-index: 1000;
  color: white;
  border-radius: 10px;
}

.title {
  font-family: Oswald;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #FFFFFF;
  margin: 25px;
  margin-left: 15px;
}

@media screen and (max-width: 767px) {
  .container{
    right: 5vw;
    left: 2vw;
    width: 93vw;
    height: 103vw;
    z-index: 1002;
  }
  .title{
    font-size: 5vw;
    margin: 6vw;
    margin-left: 4vw;
  }
}