/* Variables CSS para consistencia */
:root {
  --primary-color: rgba(228, 253, 63, 1);
  --text-color: #ffffff;
  --background-dark: #0f0f0f;
  --font-oswald: "Oswald", sans-serif;
  --font-raleway: "Raleway", sans-serif;
  --spacing-unit: 8px;
}

/* Optimización del modal principal */
.modalEntrenamiento {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 999;
}

.modalContent {
  display: flex;
  position: relative;
  width: 85%;
  max-width: 1000px;
  height: 80vh;
  background-color: #0f0f0f;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

/* Calendario */
.calendarioCont {
  width: 50%;
  padding: 25px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.placeholder {
  width: 100%;
  height: 40px; /* Tamaño ajustado al diseño de los días */
  background-color: transparent;
}

.calendario {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 5px;
}

.semana {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 15px;
}

.diaSemana {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
  text-align: center;
  font-size: 14px;
  color: white;
  border-radius: 4px;
}

.dias {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Alineación con días de la semana */
  gap: 5px;
}

.dia {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
}

.contDia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.diaNum {
  color: white;
  font-size: 16px;
}

/* Mejoras en los círculos de entrenamiento */
.contCirculo,
.divCirculoCurrent,
.divFuture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Oswald;
  font-size: 16px;
}

.contCirculo {
  background-color: rgba(228, 253, 63, 1);
  color: black;
}

.divCirculoCurrent {
  border: 2px dashed white;
}

.divFuture {
  background-color: white;
}

.iconoNoEntreno {
  width: 40px;
  height: 40px;
}

.fechaCalendario {
  font-family: Oswald;
  font-size: 25px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.contGrafica {
  width: 100%;
  height: 150px;
  margin-top: 12px;
}

canvas {
  width: 100% !important;
  height: auto !important;
}

.controlesCont {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 15px;
}

.controlIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s;
}

.controlIcon:hover {
  opacity: 1;
}

/* Contenedor de métricas */
.metricasCont,
.agregarMetricaCont {
  width: 50%;
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnCerrar {
  font-family: Oswald;
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
  cursor: pointer;
  margin-left: auto;
  background-color: transparent;
  border: none;
}

.title {
  font-family: var(--font-raleway);
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
}

.subTitle {
  font-family: Oswald;
  font-size: 16px;
  font-weight: 400;
  line-height: 17.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-right: auto;
  margin-left: 20px;
  color: #fff;
}

.contMetricaDia {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: 20px;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #fff;
}

.minutos {
  font-family: Oswald;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.balon {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 30px;
  bottom: -5px;
}

.unidad {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 17.61px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 5px;
  color: #fff;
}

.unidad2 {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.61px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
}

.contMetricaMes {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: auto;
  align-items: flex-end;
}

.contMetrica {
  display: flex;
  flex-direction: column;
}

.btnAgregar {
  width: 100%;
  height: 45px;
  border-radius: 25px;
  background-color: rgba(228, 253, 63, 1);
  color: black;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 12px;
}

.btnCancelar {
  width: 100%;
  height: 45px;
  border-radius: 25px;
  background: rgb(35, 35, 34);
  color: rgb(255, 255, 255);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 12px;
}

.contRachas {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 20px;
}

.racha {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.txt {
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.mensaje {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgb(255, 255, 255);
  margin-right: auto;
  margin-left: 30%;
  margin-top: 5px;
}

.contValor {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(228, 253, 63, 1);
  border-radius: 7px;
  height: 30px;
  width: 200px;
  margin-left: 35px;
}

.contValorM {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(228, 253, 63, 1);
  border-radius: 7px;
  height: 30px;
  width: 350px;
  margin-left: 20px;
}

.valor {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
  margin-left: 12px;
}

.valorM {
  font-family: Oswald;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.23px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.valorC {
  font-family: Oswald;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.23px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.contSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-right: auto;
  margin-left: 20px;
  margin-top: 30px;
}

.selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 12px;
  height: 50px;
  width: 45%;
}

.notSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 12px;
  height: 50px;
  width: 45%;
  cursor: pointer;
}

.notSelected:hover {
  background-color: rgba(219, 207, 207, 0.312);
}

.txtSelect {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.txtNotSelect {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 16.44px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.contInput {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  margin-right: auto;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.inp {
  width: 39%;
  height: 60px;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255);
  background-color: #0f0f0f00;
  color: white;
  font-family: Oswald;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-right: 10px;
  padding-left: 10px;
}

.inp2 {
  width: 15%;
  height: 50px;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255);
  background-color: #0f0f0f00;
  color: white;
  font-family: Oswald;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-right: 0px;
  padding-left: 15px;
}

.inp::placeholder {
  color: rgba(103, 103, 103, 0.564);
  font-family: Oswald;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.rangoFechas {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.fechaInput {
  flex: 1;
}

.fechaInput label {
  color: white;
  font-size: 14px;
}

.fechaInput input {
  width: 100%;
  padding: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
}

.fechaInput input:focus {
  outline: none;
  border-color: rgba(228, 253, 63, 1);
}

.addMultimedia {
  width: 35%;
  height: 50px;
  border-radius: 16px;
  font-family: Raleway;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.61px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0f0f0f;
  background: rgba(228, 253, 63, 1);
  margin-right: auto;
  margin-left: 20px;
  cursor: pointer;
}

.inputext {
  display: none;
}

/* Animaciones */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Media queries */
@media (max-width: 768px) {
  .modalEntrenamiento {
    align-items: flex-start;
    padding-top: 80px;
    height: 100%;
    overflow-y: auto;
  }

  .modalContent {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 60px);
    margin: 0;
    border-radius: 0;
    flex-direction: column;
    position: relative;
  }

  .calendarioCont {
    width: 90%;
    height: auto;
    min-height: 400px;
    padding: 15px;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .metricasCont,
  .agregarMetricaCont {
    width: 90%;
    height: 100vh;
    min-height: 400px;
    padding: 15px;
    padding-bottom: 30px;
  }

  .calendario {
    gap: 4px;
  }

  .controlesCont {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .btnCerrar {
    position: absolute;
    top: 5px;
    right: 0px;
    left: 15px;
    font-size: 24px;
  }

  .calendarioCont {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .placeholder {
    width: 100%;
    height: 40px;
    background-color: transparent;
  }

  .calendario {
    margin-top: 10px;
    margin-bottom: 0px;
    width: 100%;
  }

  .semana {
    margin-left: 0px;
  }

  .diaSemana {
    width: 40px;
  }

  .dias {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }

  .dia {
    text-align: center;
    position: relative;
    width: 40px;
  }

  .contDia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .diaNum {
    font-size: 18px;
    color: #fff;
    margin-bottom: 4px;
  }

  .contCirculo {
    width: 35px;
    height: 35px;
  }

  .divCirculoCurrent {
    width: 35px;
    height: 35px;
  }

  .fechaCont {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .divFuture {
    width: 35px;
    height: 35px;
  }

  .iconoNoEntreno {
    width: 35px;
    height: 35px;
  }

  .fechaCalendario {
    margin-top: 0px;
    font-size: 16px;
    line-height: 10px;
  }

  .contGrafica {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16.78px;
    margin-left: 0px;
  }

  .subTitle {
    font-size: 14px;
    line-height: 14.78px;
    margin-left: 0px;
    margin-top: 16px;
  }

  .contMetricaDia {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .minutos {
    font-size: 28px;
    line-height: 28px;
  }

  .balon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: -2px;
    bottom: -5px;
  }

  .unidad {
    font-size: 12px;
    line-height: 12.61px;
  }

  .unidad2 {
    font-size: 12px;
    line-height: 12.61px;
  }

  .contMetricaMes {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: auto;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }

  .contMetrica {
    display: flex;
    flex-direction: column;
  }

  .btnAgregar {
    width: 100%;
    height: 40px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .btnCancelar {
    width: 100%;
    height: 40px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 220px;
  }

  .contRachas {
    display: flex;
    flex-direction: column;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
  }

  .racha {
    width: 100%;
  }

  .txt {
    font-size: 14px;
    line-height: 14px;
  }

  .mensaje {
    font-family: DM Sans;
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgb(117, 112, 112);
    margin-right: auto;
    margin-left: 30%;
    margin-top: 5px;
  }

  .contValor {
    margin-left: auto;
    width: 40%;
  }

  .contValorM {
    margin-left: auto;
    width: 65%;
  }

  .valor {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
    margin-left: 12px;
  }

  .valorM {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
  }

  .valorC {
    font-family: Oswald;
    font-size: 15px;
    font-weight: 500;
    line-height: 22.23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
  }

  .contSelect {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 20px;
  }

  .selected {
    height: 40px;
    width: 50%;
  }

  .notSelected {
    height: 40px;
    width: 50%;
  }

  .notSelected:hover {
    background-color: rgba(219, 207, 207, 0.312);
  }

  .txtSelect {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: black;
  }

  .txtNotSelect {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
  }

  .contInput {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .inp {
    width: 70%;
    height: 40px;
    font-size: 20px;
    margin-right: 10px;
    padding-left: 10px;
  }

  .inp2 {
    width: 30%;
    height: 40px;
    font-size: 20px;
    margin-right: 10px;
    padding-left: 10px;
  }

  .inp::placeholder {
    color: rgba(103, 103, 103, 0.564);
    font-family: Oswald;
    font-size: 22px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .rangoFechas {
    flex-direction: column;
    gap: 10px;
    margin-right: auto;
    margin-left: 20px;
    width: 90%;
  }

  .fechaInput {
    width: 100%;
    justify-content: space-between;
  }

  .fechaInput input {
    width: 70%;
    padding: 8px;
  }

  .fechaInput label {
    width: 25%;
    font-size: 14px;
  }
}
