.modalEliminar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 170px;
    right: 380px;
    width: 750px;
    height: 400px;
    background-color: #262626;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    z-index: 1000;
    color: white;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.title{
    font-family: Oswald;
    font-size: 42px;
    font-weight: 400;
    line-height: 50.4px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 25px;
}

.cuestion{
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 45px;
    margin-top: 20px;
}

.botonera{
    display: flex;
    flex-direction: row;
    width: 450px;
    justify-content: space-between;
}

.btnC {
    width: 180px;
    height: 36px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btnC:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
}

.btnD {
    width: 180px;
    height: 36px;
    background-color: #FF0000;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    cursor: pointer;
}

.btnD:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
}