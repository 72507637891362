.conteiner {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    margin-left: 70px;
    margin-right: 0px;
}

.contUser {
    width: 20vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    border: 3px solid #E4FD3F;
}

.user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.foto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #E4FD3F;
    margin-bottom: 10px;
}

.nombre {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 3px;
}

.ubicacion {
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #FFFFFF;
}

.info {
    display: flex;
    flex-direction: column;
    width: 15vw;
    margin-top: 20px;
}

.subT {
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 2px;
}

.contRol {
    display: flex;
    width: 100px;
    height: 26px;
    background-color: rgba(151, 71, 255, 0.3);
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.rol {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
}

.contSeguidores {
    display: flex;
    flex-direction: row;
    width: 15vw;
    align-items: center;
    margin-top: 15px;
}

.seguidores {
    display: flex;
    flex-direction: column;
    width: 5vw;
    height: 40px;
    margin-right: 50px;
}

.seguidos {
    display: flex;
    flex-direction: column;
    width: 5vw;
    height: 40px;
}

.num {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;
}

.contInfo {
    display: flex;
    flex-direction: column;
    width: 20vw;
    margin-top: 25px;
}

.title {
    font-family: Oswald;
    font-size: 26px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.subTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.sobreTxt {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.trabajo {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 12px;
}

ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 15px;
}

.contBuscar {
    display: flex;
    flex-direction: column;
    width: 20vw;
    margin-top: 25px;
}

.buscarTxt {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.btnBuscar {
    width: 20vw;
    height: 40px;
    background-color: rgba(228, 253, 63, 1);
    border-radius: 8px;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: rgba(28, 29, 30, 1);
    margin-top: 20px;
}

.btnBuscar:hover{
    cursor: pointer;
    background-color: rgba(28, 29, 30, 1);
    color: #FFFFFF;
}

@media screen and (max-width: 767px) {
    .conteiner {
        width: 94vw;
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
        align-items: center;
        padding-bottom: 15vh;
    }
    
    .contUser {
        width: 92vw;
        height: 100vw;
    }
    
    .user {
        margin-top: 8vw;
        width: 60vw;
    }
    
    .foto {
        width: 30vw;
        height: 30vw;
        margin-bottom: 3vw;
    }
    
    .nombre {
        font-size: 6vw;
        margin-bottom: 2vw;
    }
    
    .ubicacion {
        font-size: 4vw;
    }
    
    .info {
        width: 60vw;
        margin-top: 20px;
    }
    
    .subT {
        font-size: 4vw;
        margin-bottom: 2vw;
        margin-left: 0px;
    }
    
    .contRol {
        width: 30vw;
        height: 8vw;
    }
    
    .rol {
        font-size: 4vw;
    }
    
    .contSeguidores {
        width: 60vw;
        margin-top: 5vw;
    }
    
    .seguidores {
        width: 25vw;
        height: 10vw;
        margin-right: 5vw;
    }
    
    .seguidos {
        width: 25vw;
        height: 10vw;
    }
    
    .num {
        font-size: 6vw;
    }
    
    .contInfo {
        width: 92vw;
        margin-top: 5vw;
    }
    
    .title {
        font-size: 6vw;
        margin-bottom: 5vw;
    }
    
    .subTitle {
        font-size: 5vw;
        margin-bottom: 5vw;
    }
    
    .sobreTxt {
        font-size: 4vw;
        margin-bottom: 4vw;
    }
    
    .trabajo {
        font-size: 4vw;
        margin-bottom: 4vw;
    }
    
    ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-inline-start: 4vw;
    }
    
    .contBuscar {
        width: 92vw;
        margin-top: 6vw;
    }
    
    .buscarTxt {
        font-size: 4vw;
        margin-bottom: 5vw;
    }
    
    .btnBuscar {
        width: 92vw;
        height: 11vw;
        font-size: 5vw;
        margin-top: 4vw;
    }
}