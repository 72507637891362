.navSecond {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #262626;
    z-index: 1000;
    padding: 10px 0;
}

.botones {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
}

.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 8px 12px;
    transition: all 0.3s ease;
}

.btn:hover {
    opacity: 0.8;
}

.icon {
    width: 35px;
    height: 35px;
    margin-bottom: 4px;
}

.txtBtn,
.txtBtnSelect {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.txtBtn {
    color: #ffffff;
}

.txtBtnSelect {
    color: #E4FD3F;
}

@media (min-width: 768px) {
    .navSecond {
        display: none;
    }
}